import * as React from 'react';
import { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import wechatlogo from '../img/wechatlogo.png'
import xiaohongshulogo from '../img/xiaohongshulogo.jpg'
import InstagramIcon from '@mui/icons-material/Instagram';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { copyText } from '../commonUtils/copy';
import { useNavigate } from 'react-router-dom';
import wechatImg from '../img/ronWechat.png';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Snackbar, Typography, IconButton, Avatar, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Chatbot from '../Chatbot/Chatbot';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PinkyAvatar from '../img/gulogo_no_text.png';
import { redbookUrl, instagramUrl, ronWechat } from '../../constants/socials';

// snackbar related
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SpeedDialButton() {
    const navigate = useNavigate();
    // chat window
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    // default open chatwindow in large screen
    const [openChatWindow, setOpenChatWindow] = useState(!matchDownMd);

    // snackbar for copy
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };
    // image dialog related
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDialogSrc, setimageDialogSrc] = useState("");
    let imgDialogHandleClose = () => { setImageDialogOpen(false) };

    function handleWechat() {
        copyText(ronWechat);
        handleClickSnackbar();
        setimageDialogSrc(wechatImg);
        setImageDialogOpen(true);
    }
    function handleRedbook() {
        window.open(redbookUrl, "_blank", "noreferrer");
    }
    function handleIns() {
        window.open(instagramUrl, "_blank", "noreferrer");
    }
    function handleBot() {
        if (matchDownMd) {
            navigate("/chatbot");
        } else {
            setOpenChatWindow(!openChatWindow);
        }
    }
    const actions = [
        { icon: <img src={wechatlogo} width={24} style={{ opacity: 1 }} alt="wechat" />, name: '复制微信号', handler: handleWechat },
        { icon: <img src={xiaohongshulogo} width={24} style={{ opacity: 1 }} alt="redbook" />, name: '访问小红书', handler: handleRedbook },
        { icon: <InstagramIcon />, name: '访问Ins', handler: handleIns },
        { icon: <LiveHelpIcon />, name: '在线客服', handler: handleBot },
    ];
    return (
        <div>
            {openChatWindow &&
                <Box
                    width={420}
                    height={570}
                    backgroundColor='#ffffff'
                    border="1px #000000 solid"
                    borderRadius='10px'
                    zIndex={1000}
                    style={{ position: 'fixed', bottom: 0, right: 16 }}>
                    <Box
                        height={60}
                        borderRadius="10px 10px 0px 0px"
                        backgroundColor='#ffc0cb'>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar alt='Pinky' src={PinkyAvatar}></Avatar>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container direction="column">
                                    <Grid item height={30} sx={{ display: 'flex', alignItems: 'end' }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Pingy </Typography>
                                        <Typography marginLeft={'6px'}> - Glory Universe 在线客服</Typography>
                                    </Grid>
                                    <Grid item height={30}>
                                        <Typography variant='caption'> 可以问我任何关于摄影，婚纱服务相关的问题哦！</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton>
                                    <KeyboardArrowDownIcon onClick={() => { setOpenChatWindow(!openChatWindow) }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Chatbot height={510} width={420} />
                </Box>}
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    已复制到粘贴板！
                </Alert>
            </Snackbar>
            <Dialog
                open={imageDialogOpen}
                onClose={imgDialogHandleClose}
                onClick={imgDialogHandleClose}
                repositionOnUpdate={false}
                aria-labelledby="image-dialog-title"
                aria-describedby="image-dialog-description"
            >
                <DialogContent
                    sx={{ padding: '0px 0px 0px 0px', height: 465, overflow: "hidden" }}>
                    <img src={imageDialogSrc} alt='zoomin' width={344}></img>
                </DialogContent>
            </Dialog>
            {!openChatWindow &&
                <SpeedDial
                    ariaLabel="SpeedDial tooltip"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.handler}
                            tooltipOpen
                        />
                    ))}
                </SpeedDial>}
        </div>
    );
}