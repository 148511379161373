import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { MyInput, MySelect } from '../widgets/MySelect/MySelect';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

function PlatformHomeSearchTool() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const handleLanguageChange = () => {
            setType(t('platformHomeSearchTool.serviceType.wedding'));
            setAlgorithm(t('platformHomeSearchTool.algorithm.recommend'));
        };
        i18n.on('languageChanged', handleLanguageChange);
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, t]);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [algorithm, setAlgorithm] = useState(t('platformHomeSearchTool.algorithm.recommend'));
    const [type, setType] = useState(t('platformHomeSearchTool.serviceType.wedding'));
    const [zipCode, setZipCode] = useState("");
    const [distance, setDistance] = useState("25 Miles");
    const [reservationDate, setReservationDate] = useState(dayjs());
    const zipRegex = /^[0-9]{5}$/;
    // TODO: better handler of the zipcode input
    const _zip = <MyInput
        label={'Zip Code'}
        value={zipCode}
        handler={setZipCode}
    />
    const _distance = <MySelect
        key={'distance-select'}
        value={distance}
        selections={["15 Miles", "25 Miles", "50 Miles", "Anywhere"]}
        handler={(newValue) => { setDistance(newValue) }} />

    // cannot use constant _type due to i18n
    // cannot use constant _algorithm due to i18n

    const _date = <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
            minDate={dayjs()}
            value={reservationDate}
            onChange={
                (newValue) => {
                    setReservationDate(newValue);
                }}
            sx={{
                "& input": { color: "#FF7A7A", padding: "8.5px 8.5px !important" },
                "& fieldset": { borderColor: 'rgba(255,150,150,0.4) !important', borderRadius: '40px' },
                "& button": { color: 'rgba(255,150,150,0.4) !important' },
            }}
        />
    </LocalizationProvider>
    if (windowWidth < 600) {
        return (
            <Grid container spacing={1} marginY={1}>
                <Grid item container display={'flex'} gap={2} justifyContent={'center'} xs={{ maxWidth: '1200px', width: '100%', margin: 'auto' }} >
                    {_zip} {_distance}
                </Grid>
                <Grid item container display={'flex'} gap={2} justifyContent={'center'} xs={{ maxWidth: '1200px', width: '100%', margin: 'auto' }} >

                    {<MySelect
                        key={'type-select'}
                        value={type}
                        selections={[t('platformHomeSearchTool.serviceType.family'), t('platformHomeSearchTool.serviceType.baby'), t('platformHomeSearchTool.serviceType.wedding')]}
                        handler={(newValue) => { setType(newValue) }} />}
                    {<MySelect
                        key={'algorithm-select'}
                        value={algorithm}
                        selections={[t('platformHomeSearchTool.algorithm.recommend'), t('platformHomeSearchTool.algorithm.closest'), t('platformHomeSearchTool.algorithm.rating')]}
                        handler={(newValue) => { setAlgorithm(newValue) }} />}
                </Grid>
                <Grid item container display={'flex'} gap={2} justifyContent={'center'} xs={{ maxWidth: '1200px', width: '100%', margin: 'auto' }} >
                    {_date}
                </Grid>
            </Grid>
        )
    }
    if (windowWidth < 900) {
        return (
            <Grid container spacing={1} marginY={1}>
                <Grid item container display={'flex'} gap={2} justifyContent={'center'} xs={{ maxWidth: '1200px', width: '100%', margin: 'auto' }} >
                    {_zip}
                    {_distance}
                    {<MySelect
                        key={'type-select'}
                        value={type}
                        selections={['家庭照', "亲子照", t('platformHomeSearchTool.serviceType.wedding')]}
                        handler={(newValue) => { setType(newValue) }} />}
                </Grid>
                <Grid item container display={'flex'} gap={2} justifyContent={'center'} xs={{ maxWidth: '1200px', width: '100%', margin: 'auto' }} >
                    {<MySelect
                        key={'algorithm-select'}
                        value={algorithm}
                        selections={[t('platformHomeSearchTool.algorithm.recommend'), "距离最近", "评分最高"]}
                        handler={(newValue) => { setAlgorithm(newValue) }} />}
                    {_date}
                </Grid>
            </Grid>
        )
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '1200px',
                width: '100%', // This makes the Box take up 100% of its parent's width
                margin: 'auto', // This centers the Box if its width is less than the maximum
            }}>
            {_zip}
            {_distance}
            {<MySelect
                key={'type-select'}
                value={type}
                selections={['家庭照', "亲子照", t('platformHomeSearchTool.serviceType.wedding')]}
                handler={(newValue) => { setType(newValue) }} />}
            {<MySelect
                key={'algorithm-select'}
                value={algorithm}
                selections={[t('platformHomeSearchTool.algorithm.recommend'), "距离最近", "评分最高"]}
                handler={(newValue) => { setAlgorithm(newValue) }} />}
            {_date}
        </Box>
    );
}
export default PlatformHomeSearchTool;