import React, { useEffect, useState } from 'react';
import { Button, Box, Grid, Tooltip } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MyStudioInfoForm from './MyStudioInfoForm';
import MyStudioTimeForm from './MyStudioTimeForm';
import MyStudioSocialMediaForm from './MyStudioSocialMediaForm';
import { Link } from 'react-router-dom';
import MyLogoAndCoverForm from './MyLogoAndCoverForm';
import MyStudioServiceSettingForm from './MyStudioServiceSettingForm';
import { getUserAndData } from '../User';
import MyStudioSummaryForm from './MyStudioSummaryForm';
import MyStudioCarousel from './MyStudioCarousel';

function MyStudioPage() {
    const [user, setUser] = useState(null);
    const [studioLink, setStudioLink] = useState('');
    const [contentSelected, setContentSelected] = useState('');
    let requested = false;

    useEffect(() => {
        if (requested) return;
        requested = true;
        loadUser();
    }, [])
    async function loadUser() {
        let userAndData = await getUserAndData();
        console.log("New userdata method")
        setUser(userAndData.user);
        if (userAndData.userData.hasOwnProperty('studioName')) {
            setStudioLink('/at/' + userAndData.userData.studioName);
        }
        setContentSelected('studioInfo'); // init selection
    }
    function handleTitleClick(contentName) {
        if (contentSelected === contentName) {
            return;
        }
        setContentSelected(contentName);
    }

    return (
        <Box className='MyStudioPage'>
            <Grid container>
                <Grid item xs={2}>
                    <Box sx={{ backgroundColor: "#eeeeee", padding: { xs: "3px", md: 2 } }}>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('studioInfo') }}
                        >
                            工作室地址
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('summaries') }}
                        >
                            工作室介绍
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000',
                                textTransform: 'none'
                            }}
                            onClick={() => { handleTitleClick('logoAndCover') }}
                        >
                            Logo与封面图
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('socialMedia') }}
                        >
                            社交平台设置
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('serviceSetting') }}
                        >
                            服务设置
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('carouselSetting') }}
                        >
                            轮播图设置
                        </Button>
                        <Button
                            fullWidth
                            size='small'
                            sx={{
                                textAlign: 'left',
                                fontSize: 18,
                                display: "flex !important",
                                justifyContent: "left !important",
                                color: '#000000'
                            }}
                            onClick={() => { handleTitleClick('manageTime') }}
                        >
                            服务时间设置
                        </Button>
                        <Tooltip title={
                            <React.Fragment>
                                开发中<br />
                                In dev plan<br />
                                ETA Nov 2023
                            </React.Fragment>
                        }>
                            <Button
                                fullWidth
                                size='small'
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    display: "flex !important",
                                    justifyContent: "left !important",
                                    color: '#000000'
                                }}
                            >
                                AI客服设置
                            </Button>
                        </Tooltip>
                    </Box>
                    {studioLink &&
                        <Box sx={{ backgroundColor: "#eeeeee", padding: { xs: "3px", md: 2 } }}>
                            <Link to={studioLink}>
                                查看工作室
                            </Link>
                        </Box>
                    }
                </Grid>
                <Grid item xs={10} padding={1}>
                    {contentSelected === 'studioInfo' && <MyStudioInfoForm user={user} />}
                    {contentSelected === 'summaries' && <MyStudioSummaryForm user={user} />}
                    {contentSelected === 'manageTime' && <MyStudioTimeForm user={user} />}
                    {contentSelected === 'socialMedia' && <MyStudioSocialMediaForm user={user} />}
                    {contentSelected === 'logoAndCover' && <MyLogoAndCoverForm user={user} />}
                    {contentSelected === 'serviceSetting' && <MyStudioServiceSettingForm user={user} />}
                    {contentSelected === 'carouselSetting' && <MyStudioCarousel user={user} />}
                </Grid>
            </Grid>
        </Box >
    );
}
export default withAuthenticator(MyStudioPage);