import * as React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography, IconButton, Avatar, Grid } from '@mui/material';
import Chatbot from '../Chatbot/Chatbot';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PinkyAvatar from '../img/gulogo_no_text.png';
import ChatIcon from '@mui/icons-material/Chat';
import { useTranslation } from 'react-i18next';

export default function ChatBotButton({ studioName }) {
    const { t } = useTranslation();
    // chat window
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    // default open chatwindow in large screen
    const [openChatWindow, setOpenChatWindow] = useState(!matchDownMd);

    // image dialog related
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageDialogSrc, setimageDialogSrc] = useState("");
    let imgDialogHandleClose = () => { setImageDialogOpen(false) };

    function handleBot() {
        setOpenChatWindow(!openChatWindow);
    }
    return (
        <div>
            {openChatWindow &&
                <Box
                    width={420}
                    height={570}
                    backgroundColor='#ffffff'
                    border="1px #000000 solid"
                    borderRadius='10px'
                    zIndex={1000}
                    style={{ position: 'fixed', bottom: 0, right: 16 }}>
                    <Box
                        height={60}
                        borderRadius="10px 10px 0px 0px"
                        backgroundColor='#ffc0cb'>
                        <Grid container>
                            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar alt='Pinky' src={PinkyAvatar}></Avatar>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container direction="column">
                                    <Grid item height={30} sx={{ display: 'flex', alignItems: 'end' }}>
                                        <Typography fontSize={'14px'} sx={{ fontWeight: 'bold' }}> {studioName}</Typography>
                                    </Grid>
                                    <Grid item height={30}>
                                        <Typography variant='caption' fontSize={'10px'} fontWeight={300}>{t('chatbot.askMeAnything')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => { setOpenChatWindow(!openChatWindow) }}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Chatbot height={510} width={420} studioName={studioName} />
                </Box>}
            <Dialog
                open={imageDialogOpen}
                onClose={imgDialogHandleClose}
                onClick={imgDialogHandleClose}
                repositionOnUpdate={false}
            >
                <DialogContent
                    sx={{ padding: '0px 0px 0px 0px', height: 465, overflow: "hidden" }}>
                    <img src={imageDialogSrc} alt='zoomin' width={344}></img>
                </DialogContent>
            </Dialog>
            {!openChatWindow &&
                <IconButton
                    sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'rgba(125,125,125,0.3)' }}
                    onClick={handleBot}
                >
                    <ChatIcon />
                </IconButton>}
        </div>
    );
}