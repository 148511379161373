import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, CardContent, Stack, FormControl, IconButton, Card, Input, Collapse, FormControlLabel, Checkbox } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DraggableImageList from '../DraggableImageList';
import { base64ToFile } from '../commonUtils';
import BlockingDialog from '../BlockingDialog';
import { getUserAndData } from '../User';
import { serviceUpdateSync, updateServiceData } from '../apis';

const UPLOAD_TEXT_1 = "(1/3) Sending updated data...";
const UPLOAD_TEXT_DONE_1 = "(1/3) Data upload done.";
const UPLOAD_IMAGE_TEXT_2 = "(2/3) Upload images...";
const UPLOAD_IMAGE_TEXT_DONE_2 = "(2/3) Image upload Done.";
const NO_NEW_IMAGE_TEXT_2 = "(2/3) No image upload needed.";
const SYNC_IMAGE_TEXT_3 = "(3/3) Sync images with data...";
const SYNC_IMAGE_TEXT_DONE_3 = "(3/3) Sync images with data done.";
const NO_NEW_IMAGE_TEXT_3 = "(3/3) No sync needed.";


function PriceOption({ priceItem, rowIndex, priceIndex, priceChangeHandler, removePriceItemInRow }) {
    const handleCheckAdjust = () => {
        let copy = JSON.parse(JSON.stringify(priceItem));
        copy.isAdjustable = !copy.isAdjustable;
        priceChangeHandler(priceIndex, copy);
    }
    const handleCheckReserveTime = () => {
        let copy = JSON.parse(JSON.stringify(priceItem));
        if (copy.timeReserve === undefined) {
            // initialization
            copy.reserveTimeInMinute = 60;
            copy.timeReserve = true;
        }
        copy.timeReserve = !copy.timeReserve;
        priceChangeHandler(priceIndex, copy);
    }
    return (
        <>
            <Box display={'flex'} gap={1} justifyContent='flex-end' marginY={1}>
                <p>价位选项 {priceIndex + 1}:</p>
                <FormControl>
                    <TextField
                        size='small'
                        label="Price (USD)"
                        value={priceItem.price}
                        sx={{ width: 110 }}
                        type="number"
                        inputProps={{
                            min: "1",
                            max: "200000",
                            step: "1"
                        }}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');
                            let copy = JSON.parse(JSON.stringify(priceItem));
                            copy.price = Number(sanitizedValue);
                            e.target.value = sanitizedValue.charAt(0) === '0' ? sanitizedValue.substring(1) : sanitizedValue;
                            priceChangeHandler(priceIndex, copy);
                        }}
                    />
                </FormControl>
                <FormControlLabel control={<Checkbox
                    checked={priceItem.isAdjustable}
                    onChange={handleCheckAdjust} />} label="允许用户调整数量" />
                <Button
                    size='small'
                    variant='outlined'
                    color='error'
                    sx={{ borderRadius: 20, height: 40, marginLeft: 'auto' }}
                    onClick={() => { removePriceItemInRow(rowIndex, priceIndex); }}
                >
                    <DeleteForeverIcon /> 删除价位选项 {priceIndex + 1}
                </Button>
            </Box>
            <Box display={'flex'} gap={1} justifyContent='flex-start' marginY={1}>
                <FormControlLabel control={<Checkbox
                    checked={priceItem.timeReserve}
                    onChange={handleCheckReserveTime} />} label="用户必须预约一个时间？" />
                <TextField
                    size='small'
                    label="时长 (分钟)"
                    value={priceItem.reserveTimeInMinute}
                    sx={{ width: 110 }}
                    type="number"
                    inputProps={{
                        min: "1",
                        max: "1440",
                        step: "1"
                    }}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');
                        let copy = JSON.parse(JSON.stringify(priceItem));
                        copy.reserveTimeInMinute = Number(sanitizedValue);
                        priceChangeHandler(priceIndex, copy);
                    }}
                />
            </Box>
            <Stack>
                <FormControl>
                    <TextField
                        size='small'
                        label="价位选项名字"
                        value={priceItem.priceName}
                        sx={{ width: 200, marginBottom: 1 }}
                        onChange={(e) => {
                            let copy = JSON.parse(JSON.stringify(priceItem));
                            copy.priceName = e.target.value;
                            priceChangeHandler(priceIndex, copy);
                        }}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        size='small'
                        label="价位选项描述"
                        fullWidth
                        multiline
                        rows={3}
                        value={priceItem.description}
                        onChange={(e) => {
                            let copy = JSON.parse(JSON.stringify(priceItem));
                            copy.description = e.target.value;
                            priceChangeHandler(priceIndex, copy);
                        }}
                    />
                </FormControl>
            </Stack>
        </>
    );
}


function ServiceCard({ serviceItem, rowIndex, changeHandler, removeRow, handleAddPriceItem, removePriceItemInRow }) {
    const [expandCard, setExpandCard] = useState(true);
    const [expandPriceOption, setExpandPriceOption] = useState(true);
    const [expandImages, setExpandImages] = useState(true);
    function handlePriceChange(priceIndex, newPriceItem) {
        let copy = JSON.parse(JSON.stringify(serviceItem));
        copy.prices[priceIndex] = newPriceItem;
        changeHandler(rowIndex, copy);
    }
    function handleImagesChange(newImagesItem) {
        let copy = JSON.parse(JSON.stringify(serviceItem));
        copy.images = newImagesItem;
        changeHandler(rowIndex, copy);
    }
    return (
        <Card sx={{ backgroundColor: '' }}>
            <CardContent>
                <Box display={'flex'} gap={1} justifyContent='flex-end'>
                    {expandCard
                        ?
                        <IconButton
                            onClick={() => { setExpandCard(!expandCard) }}
                        >
                            <ExpandLessIcon /></IconButton>
                        :
                        <IconButton
                            onClick={() => { setExpandCard(!expandCard) }}
                        >
                            <ExpandMoreIcon /></IconButton>
                    }

                    <TextField
                        size='small'
                        label="项目名字"
                        value={serviceItem.serviceName}
                        sx={{ width: 200 }}
                        onChange={(e) => {
                            let copy = JSON.parse(JSON.stringify(serviceItem));
                            copy.serviceName = e.target.value;
                            changeHandler(rowIndex, copy);
                        }}
                    />
                    <Button
                        variant='outlined'
                        sx={{ borderRadius: 20, marginLeft: 'auto' }}
                        onClick={() => { removeRow(rowIndex); }}
                        color='error'
                    >
                        <DeleteForeverIcon /> 删除项目
                    </Button>
                </Box>
            </CardContent>
            <Collapse in={expandCard}><CardContent>
                {expandPriceOption
                    ?
                    <Button
                        onClick={() => { setExpandPriceOption(!expandPriceOption) }}
                    >
                        <ExpandLessIcon />收起价位选项</Button>
                    :
                    <Button
                        onClick={() => { setExpandPriceOption(!expandPriceOption) }}
                    >
                        <ExpandMoreIcon />展开价位选项</Button>
                }
                <Button
                    variant='contained'
                    color='pink'
                    sx={{ borderRadius: 20, marginLeft: 'auto' }}
                    onClick={() => { handleAddPriceItem(rowIndex); }}
                >
                    <AddIcon /> 添加一个价位选项
                </Button>
                <Collapse in={expandPriceOption}><Box id="price-option-box">
                    {serviceItem.prices.map((priceItem, priceIndex) => (
                        <PriceOption
                            key={'price-option-' + priceIndex}
                            priceItem={priceItem}
                            rowIndex={rowIndex}
                            priceIndex={priceIndex}
                            priceChangeHandler={handlePriceChange}
                            removePriceItemInRow={removePriceItemInRow}
                        />))}
                </Box></Collapse>
                {expandImages
                    ?
                    <Button
                        onClick={() => { setExpandImages(!expandImages) }}
                    >
                        <ExpandLessIcon />收起图片</Button>
                    :
                    <Button
                        onClick={() => { setExpandImages(!expandImages) }}
                    >
                        <ExpandMoreIcon />展开图片</Button>
                }
                <Collapse in={expandImages}><Box id="price-option-box">
                    <DraggableImageList galleryList={serviceItem.images} onListChangeHandler={handleImagesChange} />
                </Box></Collapse>
            </CardContent></Collapse>
        </Card>
    );
}

function toIntegerRepresentation(num) {
    return Math.round(num * 100);
}
function toDisplayPrice(num) {
    return num / 100;
}

function MyStudioServiceSettingForm({ user }) {
    // Blocking Dialog
    const [blockingDialogOpen, setBlockingDialogOpen] = useState(false);
    const [showDismissButton, setShowDismissButton] = useState(false);
    const [blockingDialogChild, setBlockingDialogChild] = useState(null);
    const [blockingDialogTitle, setBlockingDialogTitle] = useState("Uploading... (Please do not close the web page until the upload is complete)");
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    const [serviseData, setServiseData] = useState([]);
    let requested = false;
    function serviseDataChangeHandler(serviceItemIndex, newServiceItem) {
        let copy = JSON.parse(JSON.stringify(serviseData));
        copy[serviceItemIndex] = JSON.parse(JSON.stringify(newServiceItem));
        setServiseData(copy);
    }
    function handleAddServiceRow() {
        setServiseData([...serviseData, { serviceName: '', prices: [{ price: 0, isAdjustable: false, priceName: '', description: '', reserveTimeInMinute: 60, timeReserve: true }], images: [] }]);
    }
    function handleAddPriceItem(rowIndex) {
        let copy = JSON.parse(JSON.stringify(serviseData));
        copy[rowIndex]['prices'].push({ price: 0, isAdjustable: false, priceName: '', description: '', reserveTimeInMinute: 60, timeReserve: true });
        setServiseData(copy);
    }
    function removePriceItemInRow(rowIndex, priceIndex) {
        if (rowIndex >= serviseData.length) return;
        if (priceIndex >= serviseData[rowIndex].prices.length) return;
        let copy = JSON.parse(JSON.stringify(serviseData));
        copy[rowIndex].prices.splice(priceIndex, 1);
        setServiseData(copy);
    }
    function removeRow(rowIndex) {
        if (rowIndex >= serviseData.length) return;
        let copy = JSON.parse(JSON.stringify(serviseData));
        copy.splice(rowIndex, 1);
        setServiseData(copy);
    }

    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    function loadForm(form) {
        if (!form) {
            return;
        }
        const copy = JSON.parse(JSON.stringify(form))
        for (let serviceIndex = 0; serviceIndex < copy.length; serviceIndex++) {
            for (let priceIndex = 0; priceIndex < copy[serviceIndex].prices.length; priceIndex++) {
                copy[serviceIndex].prices[priceIndex].price = toDisplayPrice(copy[serviceIndex].prices[priceIndex].price);
            }
        }
        setServiseData(copy);
    }
    async function handleUpdateServiceData(updatedServiceData, dataVersion) {
        if (!user) {
            console.error('did not login yet')
            return;
        }
        // transform data for backend
        function sliceString(str) {
            if (str.length > 20) {
                return str.slice(0, 20);
            }
            return str;
        }
        for (let serviceIndex = 0; serviceIndex < updatedServiceData.length; serviceIndex++) {
            for (let priceIndex = 0; priceIndex < updatedServiceData[serviceIndex].prices.length; priceIndex++) {
                updatedServiceData[serviceIndex].prices[priceIndex].price = toIntegerRepresentation(updatedServiceData[serviceIndex].prices[priceIndex].price);
            }
            for (let imageIndex = 0; imageIndex < updatedServiceData[serviceIndex].images.length; imageIndex++) {
                const url = updatedServiceData[serviceIndex].images[imageIndex].url;
                updatedServiceData[serviceIndex].images[imageIndex].url = sliceString(url);
            }
        }
        return await updateServiceData(user, updatedServiceData, dataVersion)
    }

    function hasEmptyString(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                console.log(key);
                return true;
            }
        }
        return false;
    }
    function priceIsInvalid(price) {
        // Check if the price is a number.
        if (typeof price !== 'number') {
            return true;
        }
        // Check price must be at least 1.
        if (price < 1) {
            return true;
        }
        const inputStr = price.toString();
        const decimalPointIndex = inputStr.indexOf('.');
        if (decimalPointIndex !== -1) {
            const decimalPart = inputStr.substring(decimalPointIndex + 1);
            if (decimalPart.length > 2) {
                // if has more than 2 decimal
                return true;
            }
        }
        return false;
    }
    async function handleSubmit(e) {
        setBlockingDialogOpen(true);
        setShowDismissButton(false);
        setBlockingDialogChild(<><p>{UPLOAD_TEXT_1}</p></>);
        e.preventDefault();
        console.log(JSON.stringify(serviseData))
        for (let serviceIndex = 0; serviceIndex < serviseData.length; serviceIndex++) {
            if (hasEmptyString(serviseData[serviceIndex])) {
                showErrorDialog(444, 'Please fill in all blanks');
                return;
            }
            for (let priceIndex = 0; priceIndex < serviseData[serviceIndex].prices.length; priceIndex++) {
                const price = serviseData[serviceIndex].prices[priceIndex].price;
                if (priceIsInvalid(price)) {
                    showErrorDialog(444, `Price is invalid: ${price}
                            \n(Price need to be a number with up to 2 decimal)
                            \n(Price must be at least 1.00)`);
                    return;
                }
            }
        }
        const updatedServiceData = JSON.parse(JSON.stringify(serviseData));
        const dataVersion = userData.dataVersion;
        const apiResponse = JSON.parse(await handleUpdateServiceData(updatedServiceData, dataVersion));
        console.log(apiResponse);
        if (apiResponse['statusCode'] === 200) {
            let updoadResult = '';
            // window.location.reload();
            console.log('Check point 1: we will not reload anymore, check for next step.')
            if (apiResponse['nextStep']) {
                if (apiResponse['nextStep']['uploadUrls']) {
                    setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: Successful."}</p>
                        <p>{UPLOAD_IMAGE_TEXT_2}</p>
                    </>);
                    console.log("uploadUrls", apiResponse['nextStep']['uploadUrls']);
                    // upload image file to s3 start here
                    updoadResult = await uploadImage(apiResponse['nextStep']['uploadUrls']);
                    setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: Successful."}</p>
                        <p>{UPLOAD_IMAGE_TEXT_DONE_2 + updoadResult}</p>
                    </>);
                } else {
                    console.error('ERROR! Upload url is not received.');
                    setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: ERROR."}</p></>);
                    setShowDismissButton(true);
                    setBlockingDialogTitle("Upload failed.")
                    return;
                }
                console.log('at this point, images upload is done.');
                setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: Successful."}</p>
                    <p>{UPLOAD_IMAGE_TEXT_DONE_2 + updoadResult}</p>
                    <p>{SYNC_IMAGE_TEXT_3}</p>
                </>);
                await serviceUpdateSync();
                setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: Successful."}</p>
                    <p>{UPLOAD_IMAGE_TEXT_DONE_2 + updoadResult}</p>
                    <p>{SYNC_IMAGE_TEXT_DONE_3}</p>
                </>);
                setShowDismissButton(true);
                setBlockingDialogTitle("Upload Done.")
            } else {
                setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: Successful."}</p>
                    <p>{NO_NEW_IMAGE_TEXT_2}</p>
                    <p>{NO_NEW_IMAGE_TEXT_3}</p>
                </>);
                setShowDismissButton(true);
                setBlockingDialogTitle("Upload Done.")
            }
        } else {
            setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + " Status: ERROR."}</p></>);
            setShowDismissButton(true);
            setBlockingDialogTitle("Upload failed.")
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            console.log('is undefined:', apiResponse['body']['errorMessage'] === undefined);
            if (apiResponse['body']['errorMessage'] === undefined) {
                showErrorDialog(apiResponse['statusCode'], JSON.parse(apiResponse['body'])['error']);
            } else {
                showErrorDialog(apiResponse['statusCode'], apiResponse['body']['errorMessage']);
            }
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('serviceData' in userAndData.userData) {
                console.log('serviceData exists');
                loadForm(userAndData.userData.serviceData);
            } else {
                console.error('serviceData not found');
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage);
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error');
        setDialogOpen(true);
    }

    async function uploadImage(uploadUrls) {
        // iterate newimages
        let uploadedCount = 0;
        let failureCount = 0;
        const totalCount = Object.keys(uploadUrls).length;
        console.log(`total count: ${totalCount}`);
        for (const service of serviseData) {
            for (const image of service['images']) {
                if (image.url.startsWith("data:")) {
                    const file = base64ToFile(image.url, image.name);
                    const uploadUrl = uploadUrls[file.name];
                    if (await uploadImageToUrl(uploadUrl, file) === false) {
                        failureCount += 1;
                    }
                    uploadedCount += 1;
                    setBlockingDialogChild(<><p>{UPLOAD_TEXT_DONE_1 + "Status: Successful."}</p>
                        <p>{UPLOAD_IMAGE_TEXT_2 + 'Progress: ' + uploadedCount + '/' + totalCount + '(' + failureCount + ' failed)'}</p>
                    </>);
                }
            }
        }
        if (failureCount === 0) {
            return 'Successfully upload all images.';
        }
        return failureCount + ' out of ' + totalCount + ' images upload failed.';
    }
    async function uploadImageToUrl(url, file) {
        const options = {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type
            }
        };
        const response = await fetch(url, options);
        if (response.ok) {
            console.log('Successfully uploaded file.');
            return true;
        } else {
            console.log('Upload failed:', response.status, response.statusText);
            return false;
        }
    }
    return (
        <Box id={'MyStudioServiceSettingForm'}>
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            <BlockingDialog
                title={blockingDialogTitle}
                open={blockingDialogOpen}
                onClose={() => { setBlockingDialogOpen(false) }}
                child={blockingDialogChild}
                showDismissButton={showDismissButton}
            />
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                    <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                        <Button
                            variant="contained"
                            color="pink"
                            onClick={handleAddServiceRow}
                            sx={{ width: 200 }}
                        >
                            添加项目
                        </Button>
                        {serviseData.map((serviceItem, index) => (
                            <ServiceCard
                                key={'service-card-' + index}
                                serviceItem={serviceItem}
                                rowIndex={index}
                                changeHandler={serviseDataChangeHandler}
                                removeRow={removeRow}
                                handleAddPriceItem={handleAddPriceItem}
                                removePriceItemInRow={removePriceItemInRow}
                            />
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            提交保存
                        </Button>
                    </Box>
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyStudioServiceSettingForm;