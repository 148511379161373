import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

function base64ToFile(base64String, fileName) {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    const file = new File([blob], fileName, { type: mimeString });

    return file;
}

/* @function filesToBase64Images
 * convert input files uploaded to base64images
 * @param {} files: array of image files. (e.g. e.target.files)
 */
async function filesToBase64Images(files) {
    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }
    const base64Images = [];
    for (const file of files) {
        try {
            const base64Image = await convertToBase64(file);
            base64Images.push(base64Image);
        } catch (error) {
            console.error(`Error converting image to base64: ${error.message}`);
        }
    }
    return base64Images;
}
/* Add random string in file name
 * @param {string} fileName - name of file
 * @param {number} lengthOfRandomChar - length of random string
 * @example addRandomCharactersToFileName('happy_cat.jpg', 3); // 'happy_cat_u2o.jpg'
 */
function addRandomCharactersToFileName(fileName, lengthOfRandomChar) {
    const extensionIndex = fileName.lastIndexOf('.');
    const name = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);
    let randomString = getRandomCharacters(lengthOfRandomChar);
    const modifiedFileName = name + '_' + randomString + extension;
    return modifiedFileName;
}

/* Add random string in file name
 * @param {number} lengthOfRandomChar - length of random string
 * @example addRandomCharactersToFileName(3); //  ==='u2o'
 */
function getRandomCharacters(lengthOfRandomChar) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < lengthOfRandomChar; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    return randomString;
}

/* @function generateThumbnails
 * generate a list of thumbnail image of (150x150 by default) for files
 * @param {} files: array of image files. (e.g. e.target.files)
 */
async function generateThumbnails(files,) {
    const thumbnailPromises = [];
    let thumbnailUrls = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        thumbnailPromises.push(
            new Promise((resolve) => {
                reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        const MAX_WIDTH = 150;
                        const MAX_HEIGHT = 150;
                        let width = image.width;
                        let height = image.height;
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(image, 0, 0, width, height);
                        const thumbnailURL = canvas.toDataURL('image/png');
                        resolve(thumbnailURL);
                    };
                };
                reader.readAsDataURL(file);
            })
        );
    }
    await Promise.all(thumbnailPromises).then((thumbnailURLs) => {
        thumbnailUrls = [...thumbnailURLs];
    });
    return thumbnailUrls;
}

export async function uploadFileToUrl(file, fileType, url) {
    const options = {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': fileType
        }
    };
    const response = await fetch(url, options);
    if (response.ok) {
        console.log('Successfully uploaded file.');
        return true;
    } else {
        console.log('Upload failed:', response.status, response.statusText);
        return false;
    }
}

export function getAspectRatio(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                resolve(aspectRatio);
            };
            img.onerror = reject;
            img.src = reader.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

async function ratioAssert(file, onFailed, onSucceed, widthRequired, heightRequired, errorAccepted = 0) {
    const reader = new FileReader();
    reader.onload = function (event) {
        const img = new Image();
        img.onload = async function () {
            const width = img.width;
            const height = img.height;
            const actualRatio = width / height;
            const ratioRequired = widthRequired / heightRequired;
            if (Math.abs((actualRatio - ratioRequired) / ratioRequired) <= errorAccepted) {
                await onSucceed();
            } else {
                alert(`Aspect ratio must be ${heightRequired}:${widthRequired} (height:width)`);
                await onFailed();
                return;
            }
        };
        img.src = event.target.result;
    };
    reader.readAsDataURL(file);
}

/**
 * conver time in minute to time
 * @param {number} minutes time in minute
 * @returns {string} time in format: "15h 34m"
 */
function formatTime(minutes) {
    if (isNaN(minutes) || minutes < 0) {
        return "Invalid input";
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursString = hours > 0 ? `${hours}h` : '';
    const minutesString = remainingMinutes > 0 ? `${remainingMinutes}m` : '';
    return `${hoursString} ${minutesString}`.trim();
}

/**
 * convert time in minutes
 * @param {string} timeString time in format: "23:59"
 * @returns {number} timeInMinute
 */
function timeToMinutes(timeString) {
    const [hour, minute] = timeString.split(':').map(Number);
    return hour * 60 + minute;
}

/**
 * conver time in minute to time
 * @param {number} timeInMinute time in minute
 * @returns {string} time in format: "15:34"
 */
function minutesToTime(timeInMinute) {
    const hour = Math.floor(timeInMinute / 60);
    const minute = timeInMinute % 60;
    // Ensure that the hour and minute are formatted with leading zeros if necessary
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
    return `${formattedHour}:${formattedMinute}`;
}

function getReservableSlotsWithInterval(openHours, selectedDate, reservationLength, bookedTime) {
    if (bookedTime == undefined) {
        console.log("????bookedTime")
    }
    const day = dayjs(selectedDate).startOf('day');
    const dayOfWeek = day.format('dddd');
    const dayOpenHours = openHours.filter(item => item.day === dayOfWeek);
    if (!dayOpenHours) {
        return []; // No open hours for the selected day
    }

    const dateToBookedTimeRange = {}
    function recordBookedTime(key, value) {
        if (!(key in dateToBookedTimeRange)) {
            dateToBookedTimeRange[key] = [];
        }
        dateToBookedTimeRange[key].push(value);
    }
    // string YYYYMMDD : {start: start time in minute, end: end time in minute}
    // example {"20231222": [{"start": 600, "end": 1200}]} meaning Dec 22, 2023 10:00~20:00 is booked.
    bookedTime.forEach(item => {
        const start = dayjs.tz(item.time_start, item.time_zone);
        let end = dayjs.tz(item.time_end, item.time_zone);
        // if (end.isBefore(day)) return;
        let dateKey = start.format('YYYYMMDD');
        if ((start.isSame(end, 'year') && start.isSame(end, 'month') && start.isSame(end, 'day'))) {
            const startTimeInMinute = timeToMinutes(start.format("HH:mm"));
            const endTimeInMinute = timeToMinutes(end.format("HH:mm"));
            recordBookedTime(dateKey, { "start": startTimeInMinute, "end": endTimeInMinute });
        } else {
            // TODO: this chunk of code is not tested...
            let currentStart = start.clone();
            let endOfCurrentDay = start.clone().endOf('day');
            for (let breakDateLimit = 30; breakDateLimit > 0 && currentStart.isBefore(end); breakDateLimit--) {
                let currentEnd = endOfCurrentDay.isBefore(end) ? endOfCurrentDay : end;
                const startTimeInMinute = timeToMinutes(currentStart.format("HH:mm"));
                const endTimeInMinute = timeToMinutes(currentEnd.format("HH:mm"));
                recordBookedTime(currentStart.format('YYYYMMDD'), { "start": startTimeInMinute, "end": endTimeInMinute });

                currentStart = currentEnd.clone().add(1, 'minute').startOf('day');
                endOfCurrentDay = currentStart.clone().endOf('day');
            }
        }

    });
    // console.log(dateToBookedTimeRange);
    const dayBookedTimes = dateToBookedTimeRange[day.format('YYYYMMDD')];

    const dayOpenHoursInMinutes = dayOpenHours.map(item => { return { "start": timeToMinutes(item['start']), "end": timeToMinutes(item['end']) } })
    const availableTimes = getAvailableTime(dayOpenHoursInMinutes, dayBookedTimes);
    // console.log('dayOpenHoursInMinutes', dayOpenHoursInMinutes);
    // console.log('dayBookedTimes', dayBookedTimes);
    // console.log('availableTimes', availableTimes);
    const reservableSlots = [];
    for (let availableIndex = 0; availableIndex < availableTimes.length; availableIndex++) {
        const availableTime = availableTimes[availableIndex];
        const startTimeInMinute = availableTime['start'];
        const endTimeInMinute = availableTime['end'];
        const lastOpenTimeInMinute = endTimeInMinute - reservationLength;
        for (let startTime = startTimeInMinute; startTime <= lastOpenTimeInMinute; startTime += 15) {
            reservableSlots.push(minutesToTime(startTime));
        }
    }
    return reservableSlots;
}

function isReservationTimeValid(time) {
    if (time === null) return false; // null check
    if (time.isBefore(dayjs())) return false; // is before now
    return true;
}
function checkReservationTimeError(time) {
    if (time === null) return 'Please select a time above'; // null check
    if (time.isBefore(dayjs())) return 'Please reserve a time in the future, not in the past.'; // is before now
    return '';
}

const calculateEndTime = (startTime, length) => {
    if (startTime === null) return null;
    return startTime.add(length, 'minute');
}
function getAvailableTime(openTimes, bookedTimes) {
    if (bookedTimes === undefined || bookedTimes.length == 0) return openTimes;
    let availableTimes = [];

    openTimes.forEach(open => {
        let start = open.start;
        let end = open.end;

        bookedTimes.forEach(booked => {
            if (booked.start < end && booked.end > start) {
                // Adjust the start and end to exclude the booked time
                if (booked.start > start) {
                    availableTimes.push({ start: start, end: booked.start });
                }
                start = booked.end;
            }
        });

        // If there's remaining time after booked times, add it to available times
        if (start < end) {
            availableTimes.push({ start: start, end: end });
        }
    });

    return availableTimes;
}

export const myOutputFormat = 'YYYY-MM-DD HH:mm:ss'
export const reviewTimeFormat = 'YYYY-MM-DD-HH:mm:ss'
export function readUTCTime(utcDateString, inputFormat = myOutputFormat, outputFormat = myOutputFormat) {
    const utcDate = dayjs.utc(utcDateString, inputFormat);
    const localDate = utcDate.local();
    const formattedLocalDateString = localDate.format(outputFormat)
    const formattedUtcDateString = utcDate.format(outputFormat)
    return {
        utc: formattedUtcDateString,
        local: formattedLocalDateString,
    }
}

export {
    base64ToFile,
    filesToBase64Images,
    addRandomCharactersToFileName,
    getRandomCharacters,
    generateThumbnails,
    ratioAssert,
    formatTime,
    getReservableSlotsWithInterval,
    isReservationTimeValid,
    checkReservationTimeError,
    calculateEndTime
};