import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/material';


export default function PaymentSuccessPage() {
    const [orderData, setOrderData] = useState([]);
    let requestSent = false;
    useEffect(() => {
        if (requestSent) return;
        requestSent = true;
        async function getOrderData() {
            const query = new URLSearchParams(window.location.search);
            const orderID = query.get("orderID");
            const apiResponse = await getOrder(orderID);
            setOrderData(apiResponse);
        }
        getOrderData();
    }, [])

    function getTimeString(unixTimestamp) {
        const jsUnixTime = unixTimestamp * 1000;
        const date = new Date(jsUnixTime);
        return `${(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleTimeString()}`
    }
    function getPaymentStatus(charge) {
        if (!charge.paid) return 'unpaid';
        if (!charge.refunded) return 'paid';
        if (charge.paid && charge.refunded) return 'refunded';
    }

    async function getOrder(orderID) {
        const requestBody = { path: 'PaymentSuccess', orderID: orderID };
        // moved this to stripe webhook
        let apiResponse = undefined;
        await fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/makeorder',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(requestBody),
            }).then(function (response) {
                return response.text();
            }).then(response => {
                apiResponse = JSON.parse(response);
                console.log(apiResponse);
            })
            .catch(error => {
                console.error(error);
            });
        return apiResponse;
    }

    return (
        <div className='PaymentSuccessPage'>
            <Stack sx={{ px: 1 }} className='pageContent'>
                <Typography sx={{ mt: 3 }} fontSize={{ xs: 30, sm: 60, lg: 80 }} fontFamily={"CustomFont"} align='center'>
                    Glory Universe Photography Studio LA
                </Typography>
                {JSON.stringify(orderData) !== '[]' ? (
                    <Box>
                        <h3>Order Info</h3>
                        <p>Payment Status: {getPaymentStatus(orderData)}</p>
                        <p>Total Amount: {orderData.amount / 100} {orderData.currency}</p>
                        {orderData.refunded && <p>Refunded Amount: {orderData.amount_refunded / 100} {orderData.currency}</p>}
                        <p>Created Time: {getTimeString(orderData.created)}</p>
                        <p>Email: {orderData.receipt_email || orderData.billing_details?.email}</p>
                    </Box>
                ) : (
                    <p> Loading ... </p>
                )}
            </Stack>
        </div>
    );
}