import { createTheme } from '@mui/material/styles';

export const pink = {
  1: '#FF7A7A',
  2: '#FF9696',
  3: '#FFA5A5',
  4: '#FFB5B5',
  5: '#FFD5D5',
  6: '#FFE5E5',
  7: '#FFF1F1',
}
const yellow = {
  1: '#FFC107',
  2: '#FFD042',
  3: '#FFEB3B',
  4: '#FFF176',
  5: '#FFF7A6',
  6: '#FFFAD0',
  7: '#FFFCE5',
}
export const orange = {
  1: '#FF725E',
  2: '#FF8862',
  3: '#FCA268',
  4: '#FFBF63',
  5: '#FFC880',
  6: '#FFCBA4',
  7: '#FFEBD9',
}
export const blue = {
  1: '#30A9E0',
  2: '#79D0FF',
  3: '#96CFFF',
  4: '#B0E0FF',
  5: '#C2E4FF',
  6: '#D6F0FF',
  7: '#E1F7FF',
}
const green = {
  1: '#96FF96',
  2: '#A0FFCC',
  3: '#B3FFB3',
  4: '#C7FFD5',
  5: '#D5FFD5',
  6: '#ECFFEC',
  7: '#F3FCF3',
}
const grey = {
  1: '#333333',
  2: '#666666',
  3: '#CCCCCC',
  4: '#D9D9D9',
  5: '#F2F2F2',
  6: '#FFFFFF',
}
const black = '#000000' // rgba(0, 0, 0, 0.87)
const red = '#FF0000'
export const white = '#FFFFFF'

const airyVibeTheme = createTheme({
  typography: {
    "fontFamily": ` "Roboto", "CustomCNFont", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 700,
    "fontWeightBold": 900
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 20
        },
      },
    },
  },
  palette: {
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
      disabled: 'rgba(0,0,0,0.38)',
      divider: 'rgba(0,0,0,0.12)'
    },
    action: {
      active: 'rgba(0,0,0,0.54)',
      activatedOpacity: 0.54,
      hover: 'rgba(0,0,0,0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0,0,0,0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      disabledOpacity: 0.12,
      focus: 'rgba(0,0,0,0.12)',
      focusOpacity: 0.12,
    },
    mode: 'light', // verses dark
    error: {
      main: red,
      contrastText: white,
    },
    red: {
      main: red,
      contrastText: white,
    },
    pink: {
      main: pink[1],
      light: pink[4],
      dark: pink[2],
      contrastText: white,
    },
    pink2: {
      main: pink[2],
      light: pink[4],
      dark: pink[3],
      contrastText: white,
    },
    pinkContrast: {
      main: white,
      contrastText: pink[1],
    },
    background: {
      main: pink[7],
      contrastText: white,
    },
    white: {
      main: white,
      contrastText: pink[1],
    },
    yellow: yellow,
    orange: orange,
    green: {
      main: green[1],
      contrastText: grey[2],
    },
    blue: blue,
    black: black,
    grey: grey,
    primary: {
      main: pink[1],
      light: pink[4],
      dark: pink[2],
      contrastText: white,
    },
    secondary: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    // Add more colors as needed
  },
});

export default airyVibeTheme

export const pinkStyle = {
  backgroundColor: `${pink[1]} !important`,
  color: `${white} !important`
}

export const pinkContrastStyle = {
  backgroundColor: `${pink[1]} !important`,
  color: `${white} !important`
}
