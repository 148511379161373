import React from 'react';

import Navbar from './Navbar/Navbar';
import { Stack, Typography, Button, Box } from '@mui/material';
import SpeedDialButton from './SpeedDialButton/SpeedDialButton';


export default function TestPaymentPage() {
    async function handleCheckout(priceIds, quantities) {
        console.log('button clicked.')
        const requestBody = { path: 'testAug13' };
        if (priceIds && quantities) {
            requestBody.priceIds = priceIds;
            requestBody.quantities = quantities;
        }
        await fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/makeorder',
            {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                redirect: "follow", // manual, *follow, error
                body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
            }).then(function (response) {
                return response.text();
            }).then(response => {
                let res = JSON.parse(response);
                if (res.statusCode === 303) {
                    const redirectUrl = res.headers.Location;
                    window.location.href = redirectUrl;
                } else {
                    console.log(response);
                    console.error('unhandled request')
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    return (
        <div className='TestPaymentPage'>
            <SpeedDialButton />
            <Navbar />
            <Stack sx={{ px: 1 }} className='pageContent'>
                <Typography sx={{ mt: 3 }} fontSize={{ xs: 30, sm: 60, lg: 80 }} fontFamily={"CustomFont"} align='center'>
                    Airy Vibe
                </Typography>
                <Typography fontSize={{ xs: 16, md: 20, lg: 24 }}>
                    有序开发中!为了节省成本，降低服务价格，我们自己开发网站！
                </Typography>
                <Box>
                    <Button
                        variant='contained'
                        onClick={async () => { handleCheckout() }}>
                        checkout cheap test
                    </Button>
                    <Button
                        variant='contained'
                        onClick={async () => { handleCheckout(['price_1NrvS7CoVavKKVS2pcR4ctoW'], [1]) }}>
                        checkout 53 Ci's price?
                    </Button>
                </Box>
            </Stack>
        </div>
    );
}