import * as React from 'react';
import NiceTab from '../commonUtils/Tabs';
import { Box } from '@mui/material';
import MyStudioTimeSetting from './MyStudioTimeSetting';
import MyStudioTimeBooked from './MyStudioTimeBooked';

function MyStudioTimeForm({ user }) {
    const tabCount = 3;
    const tabTitles = ['Overview', 'Open Time', 'Booked Time'];
    const tabContents = [<>in development</>, <MyStudioTimeSetting user={user} />, <MyStudioTimeBooked user={user} />];

    return (
        <Box id={'MyStudioTimeForm'}>
            <NiceTab
                defaultValue={1}
                size={tabCount}
                titles={tabTitles}
                contents={tabContents}
            />
        </Box>
    );
}
export default MyStudioTimeForm;