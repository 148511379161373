import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserAndData } from '../User';
import { getAllStudioOrders, getAllCustomerOrders } from '../apis';
import { useTranslation } from 'react-i18next';
import { AliceSelect } from '../../widgets/MySelect/MySelect';
import CopyTypography from '../../widgets/CopyTypography/CopyTypography';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import { StyledBreadcrumb } from '../BreadCrumb/BreadCrumb';
import airyVibeTheme, { pinkStyle, pink, white, orange, blue } from '../../airyVibeTheme';

import ConfirmedIcon from '../../icons/ConfirmedIcon.svg';
import ConfirmedActiveIcon from '../../icons/ConfirmedActiveIcon.svg';
import OrderCompletedIcon from '../../icons/OrderCompletedIcon.svg';
import OrderCompletedActiveIcon from '../../icons/OrderCompletedActiveIcon.svg';
import ReviewedIcon from '../../icons/ReviewedIcon.svg';
import ReviewedActiveIcon from '../../icons/ReviewedActiveIcon.svg';
import WalletIcon from '../../icons/WalletIcon.svg';
import WalletActiveIcon from '../../icons/WalletActiveIcon.svg';
import TimePendingTaskIcon from '../../icons/TimePendingTaskIcon.svg';
import TimePendingTaskActiveIcon from '../../icons/TimePendingTaskActiveIcon.svg';

function sortOrdersByTimeStart(a, b) {
    const timeA = new Date(a.time_reserved?.time_start).getTime();
    const timeB = new Date(b.time_reserved?.time_start).getTime();
    return timeA - timeB;
}
function sortOrdersByOrderID(b, a) {
    if (a.orderID < b.orderID) {
        return -1;
    }
    if (a.orderID > b.orderID) {
        return 1;
    }
    return 0;
}


// features to do: (1) grouped by status; (2) simple search

function Orders() {

    const { t } = useTranslation();

    const orderStatus = [
        { filterFn: (order) => { return order.status === 'paid'; }, icon: WalletIcon, activeIcon: WalletActiveIcon, text: t('order.statusPaid') },
        { filterFn: (order) => { return order.status === 'accepted'; }, icon: ConfirmedIcon, activeIcon: ConfirmedActiveIcon, text: t('order.statusAccepted') },
        { filterFn: (order) => { return order.status === 'photoPicking'; }, icon: TimePendingTaskIcon, activeIcon: TimePendingTaskActiveIcon, text: t('order.statusPhotoPicking') },
        { filterFn: (order) => { return order.status === 'completed'; }, icon: OrderCompletedIcon, activeIcon: OrderCompletedActiveIcon, text: t('order.statusCompleted') },
        { filterFn: (order) => { return order.review !== undefined; }, icon: ReviewedIcon, activeIcon: ReviewedActiveIcon, text: t('order.statusReviewed') },
        { filterFn: () => { return true; }, text: t('order.allOrders') },
    ];
    const sorters = [
        { sortFn: sortOrdersByTimeStart, text: t('order.sorter.reserveTimeAscending') },
        { sortFn: (a, b) => { return sortOrdersByTimeStart(b, a) }, text: t('order.sorter.reserveTimeDescending') },
        { sortFn: (a, b) => { return sortOrdersByOrderID(b, a) }, text: t('order.sorter.orderTimeAscending') },
        { sortFn: sortOrdersByOrderID, text: t('order.sorter.orderTimeDescending') },
    ]

    const navigate = useNavigate();
    const [init, setInit] = useState(true);
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [filteredOrderData, setFilteredOrderData] = useState([]);
    const [activatedIndex, setActivatedIndex] = useState(5);
    const [sorterIndex, setSorterIndex] = useState(0);

    let requested = false;

    function OrderCard({ order }) {
        return (
            <Card sx={{ borderRadius: 3 }}>
                <Box sx={{ p: 2 }}>
                    <Box display="flex" justifyContent='space-between' flexWrap="wrap">
                        <Typography fontSize={14} fontWeight={'medium'} color={blue[1]}>
                            {t('order.orderName', { studioName: order.studioName })}
                        </Typography>
                        <Button size='small' sx={{ p: 0, color: `${pink[1]}` }}
                            onClick={() => {
                                navigate('/order/' + order.orderID);
                            }}
                        >
                            {t('order.seeDetails')}
                        </Button>
                    </Box>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Typography fontSize={14} fontWeight="medium" mr={1}>
                            {t('order.orderID')}:
                        </Typography>
                        <CopyTypography fontSize={14} fontWeight={400} >
                            {order.orderID}
                        </CopyTypography>
                    </Box>

                    {order.time_reserved &&
                        <Box display="flex" alignItems="center">
                            <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                                {t('order.photoServiceTime')}:
                            </Typography>
                            <Typography fontSize={14} fontWeight={400}>
                                {formatDateRange(order.time_reserved.time_start, order.time_reserved.time_end)}
                            </Typography>
                        </Box>
                    }
                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                            {t('order.orderStatus')}:
                        </Typography>
                        <Typography fontSize={14} fontWeight={400}>
                            {order.status}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} fontWeight={'medium'} mr={1}>
                            {t('order.paidAmount')}:
                        </Typography>
                        <Typography fontSize={14} fontWeight={400}>
                            ${((order.charge_item?.amount_captured ?? 0) / 100).toFixed(2)}
                        </Typography>
                        {order.invoice?.hosted_invoice_url &&
                            <Button size='small' sx={{ p: 0, ml: 3, color: `${blue[1]}` }}
                                onClick={() => {
                                    // close todo: drawer from bottom
                                    window.open(order.invoice.hosted_invoice_url, '_blank');
                                }}
                            >
                                {t('order.stripeReceipt')}
                            </Button>
                        }
                    </Box>
                </Box>
            </Card >
        );
    }

    useEffect(() => {
        if (requested) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        requested = true;
        console.log('Getting order IDs...');
        async function loadOrders() {
            const userAndData = await getUserAndData();
            if (userAndData.user === null) {
                console.warn("Load user failed.\nMaybe you are not login yet.\nRedirect to login page in 5 second.");
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
            setUser(userAndData.user);
            if (userAndData.userData === null) {
                console.warn("Load user data failed.");
                return;
            }
            setUserData(userAndData.userData);
            let response;
            if (userAndData.userData.userType === 'photographer') {
                response = await getAllStudioOrders(userAndData.user);
            } else {
                response = await getAllCustomerOrders(userAndData.user);
            }
            console.log(response);
            response = JSON.parse(response);
            if ('statusCode' in response && response.statusCode === 410) {
                console.error("token expired, please refresh");
            } else {
                sortOrdersByTime(response);
            }
            setInit(false);
        }
        loadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let result = orderData;
        if (result === undefined) return;
        if (activatedIndex !== undefined) {
            result = result
                .filter(orderStatus[activatedIndex].filterFn);
        }
        if (activatedIndex !== undefined) {
            result = result
                .sort(sorters[sorterIndex].sortFn);
        }
        setFilteredOrderData(result);
    }, [activatedIndex, orderData, sorterIndex]);

    function sortOrdersByTime(orderData) {
        const orderArray = JSON.parse(JSON.stringify(orderData));
        if (Array.isArray(orderArray)) {
            setOrderData(JSON.parse(JSON.stringify(orderData)));
            setFilteredOrderData(JSON.parse(JSON.stringify(orderData)));
        }
        return orderData;
    }

    return (
        <Box className='OrdersPage'
            sx={{ bgcolor: `${airyVibeTheme.palette.background.main}` }}
            maxWidth='100vw'
        >
            {init ?
                <p>loading...</p> :
                <Box
                    sx={{ px: 1, paddingX: { xs: 1, md: 2 }, py: { xs: "3px", md: 1 } }}
                    className='pageContent'
                    maxWidth='1920px'
                    display="flex" flexDirection={'column'} gap={1}
                >
                    <CustomizedBreadcrumbs />
                    {userData.userType === 'photographer' &&
                        <Box display="flex" gap={1} alignItems="center">
                            <Avatar
                                src={userData.logo || ""}
                                alt="Studio"
                                sx={{
                                    border: `solid ${pink[1]} 2px`,
                                    height: { xs: 36, md: 40, lg: 44 },
                                    width: { xs: 36, md: 40, lg: 44 },
                                }}
                            />
                            <Typography fontWeight={'medium'}>{userData.studioName}</Typography>
                            {/** TODO: two buttons */}
                        </Box>
                    }
                    <MyOrderCard activatedIndex={activatedIndex} setActivatedIndex={setActivatedIndex} />
                    <OrderList activatedIndex={activatedIndex} setActivatedIndex={setActivatedIndex} />
                </Box>
            }
        </Box >
    );

    function OrderList({ activatedIndex, setActivatedIndex }) {
        return (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Typography fontWeight={'medium'} fontSize={14}>
                    {orderStatus[activatedIndex]?.text} {"(" + filteredOrderData.length + ")"}
                </Typography>
                {/**filters and sorter*/}
                <Box display="flex" gap={1} alignItems="center">
                    <AliceSelect
                        value={sorters[sorterIndex].text}
                        selections={sorters.map(m => m.text)}
                        handlerByIndex={setSorterIndex}
                        width='190px'
                    >
                    </AliceSelect>
                    <AliceSelect
                        value={orderStatus[activatedIndex].text}
                        selections={orderStatus.map(o => o.text)}
                        handlerByIndex={setActivatedIndex}
                    >
                    </AliceSelect>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    {filteredOrderData.map((order, index) => (
                        <OrderCard
                            key={"order-" + index}
                            order={order}
                        />
                    ))}
                </Box>
            </Box >
        );
    }

    function MyOrderCard({ activatedIndex, setActivatedIndex }) {
        return (
            <Box sx={{ borderRadius: 3, border: `solid 2px ${pink[2]}`, padding: 0, backgroundColor: `${white}`, }}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'space-between'}
                    sx={{
                        bgcolor: `${pink[2]}`,
                        borderRadius: 'inherit',
                        margin: '-2px',
                        py: 1,
                        px: 2
                    }}
                >
                    <Typography color={white} fontWeight={'medium'}>我的订单</Typography>
                    <Button
                        sx={{
                            color: `${pink[2]}`,
                            bgcolor: `${white}`,
                            borderRadius: '20px',
                            border: '0px',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '12px',
                            "&.MuiButton-outlined:hover": {
                                bgcolor: `${pink[6]}`,
                                border: '0px',
                            }
                        }}
                        variant='outlined'
                        onClick={() => { setActivatedIndex(5); }}
                    >全部</Button>
                </Box>
                <Box p={1} display="flex" sx={{ justifyContent: { xs: 'space-between', sm: 'flex-start' } }}>
                    {orderStatus.filter((status, index) => index < 5).map((status, index) => (
                        <Button
                            key={'status-' + status.text}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: { xs: 'auto', sm: '80px' },
                                gap: '12px',
                                p: '4px',
                                alignItems: 'center',
                                overflow: 'hidden'
                            }}
                            onClick={() => { setActivatedIndex(index); }}
                        >
                            <img src={index === activatedIndex ? status.activeIcon : status.icon} alt="My Icon" width={36} height={36} />
                            <Typography
                                fontWeight={index === activatedIndex ? 'bold' : 'medium'}
                                color={index === activatedIndex ? `${orange[1]}` : 'grey'}
                                sx={{

                                    fontSize: { xs: 10, md: 14 }
                                }}>
                                {status.text}
                            </Typography>
                        </Button>
                    ))}
                </Box>
            </Box >
        );
    }
}

export default Orders;

function CustomizedBreadcrumbs() {
    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/"
                    label="Home"
                    sx={pinkStyle}
                />
                <StyledBreadcrumb sx={pinkStyle} label="My Orders" />
            </Breadcrumbs>
        </div>
    );
}

/**
 * Formats the start and end times into a human-readable date and time range.
 * If the start and end times fall on different dates, both dates will be displayed.
 *
 * @param {string} timeStart - The start time in ISO format (e.g., "2024-06-08T09:15:00").
 * @param {string} timeEnd - The end time in ISO format (e.g., "2024-06-08T10:15:00").
 * @returns {string} The formatted date and time range.
 *
 * @example
 * // Same day
 * const timeStart = "2024-06-08T09:15:00";
 * const timeEnd = "2024-06-08T10:15:00";
 * console.log(formatDateRange(timeStart, timeEnd));
 * // Output: "6/8/2024, 9:15 AM - 10:15 AM"
 *
 * @example
 * // Different days
 * const timeStart = "2024-06-08T09:15:00";
 * const timeEnd = "2024-06-09T10:15:00";
 * console.log(formatDateRange(timeStart, timeEnd));
 * // Output: "6/8/2024, 9:15 AM - 6/9/2024, 10:15 AM"
 */
function formatDateRange(timeStart, timeEnd) {
    const startDate = new Date(timeStart);
    const endDate = new Date(timeEnd);

    const optionsDate = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

    const formattedStartDate = new Intl.DateTimeFormat('en-US', optionsDate).format(startDate);
    const formattedEndDate = new Intl.DateTimeFormat('en-US', optionsDate).format(endDate);
    const formattedStartTime = new Intl.DateTimeFormat('en-US', optionsTime).format(startDate);
    const formattedEndTime = new Intl.DateTimeFormat('en-US', optionsTime).format(endDate);

    if (formattedStartDate === formattedEndDate) {
        return `${formattedStartDate}, ${formattedStartTime} - ${formattedEndTime}`;
    } else {
        return `${formattedStartDate}, ${formattedStartTime} - ${formattedEndDate}, ${formattedEndTime}`;
    }
}
