import './Chatbot.css';
import './bubble.css';
import React, { useEffect, useState } from 'react';
import PinkyAvatar from '../img/gulogo_no_text.png';
import userAvatar from '../img/userAvatar.png';
import { getUserID } from '../commonUtils/getUserID';
import SendIcon from '@mui/icons-material/Send';
import { Container, TextField, IconButton, InputAdornment, Chip, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Chatbot({ height, width, studioName }) {
  const { t, i18n } = useTranslation();
  const [chat, setChat] = useState([{ "assistant": t('chatbot.greetingMessage', { studioName }) }]);
  const [data, setData] = useState([]);
  const [userChat, setUserChat] = useState([]);
  const [assistantChat, setAssistantChat] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  useEffect(() => {
    const handleLanguageChange = () => {
      chat[0] = { "assistant": t('chatbot.greetingMessage', { studioName }) };
      setChat(chat);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, t]);


  function chipClickHandler(event) {
    document.getElementById("userQuestion").value = event.target.innerText;
  }

  async function submitHandler(e) {
    e.preventDefault();
    if (document.getElementById("userQuestion").value.trim() === "") {
      return;
    }
    setSendButtonDisabled(true);
    // TODO: hide chat hint once the user makes a completed chat with bot
    const userQuestion = document.getElementById("userQuestion").value;
    document.getElementById("userQuestion").value = "";
    const requestData = [...data, { "role": "user", "content": userQuestion }];
    setData(requestData);
    let lastChat = chat;
    setChat([...chat, { "assistant": "...", "user": userQuestion }]);

    const requestUserChat = [...userChat, userQuestion];
    setUserChat(requestUserChat);
    const requestBody = { "userChat": requestUserChat, "assistantChat": assistantChat, "userID": await getUserID(), studioName: studioName };
    fetch('https://a8z6txh0d7.execute-api.us-west-2.amazonaws.com/api/chatbot',
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(requestBody), // body data type must match "Content-Type" header

      }).then(function (response) {
        return response.text();
      }).then(response => {
        let newConversation = {};
        let assistantMessage = "";
        response = JSON.parse(response);
        if (!response["body"]) {
          assistantMessage = "An Error occurred.";
        } else {
          assistantMessage = response["body"];
        }
        newConversation = { "user": userQuestion, "assistant": assistantMessage };
        lastChat.push(newConversation);
        setData([...data, { "role": "assistant", "content": assistantMessage }]);
        setChat(lastChat);
        const returnAssistantChat = [...assistantChat, assistantMessage];
        setAssistantChat(returnAssistantChat);
        return;
      })
      .catch(error => {
        console.error(error);
        const newConversation = { "user": userQuestion, "assistant": "An Error occurred." };
        lastChat.push(newConversation);
        setChat(lastChat);
        setAssistantChat("An Error occurred.");
      });
    setSendButtonDisabled(false);
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // submitHandler(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };// eslint-disable-next-line
  }, []);

  useEffect(() => {
    var objDiv = document.getElementsByClassName("left");
    objDiv[objDiv.length - 1].scrollIntoView();

  }, [chat])

  return (
    <Box id="chatbot" className="chatbot" height={height} width={width}>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <Box
        className='chatContainer'
        height={height ? height - 116 : window.innerHeight - 245}>
        {chat.map((c, index) =>
          <Box key={index}>
            {c["user"] &&
              <Box className='bubble right'>
                <Box className='avatar'> <img src={userAvatar} alt='user' /></Box>
                <Box className="wrap"><p className='content'>{c["user"]}</p></Box>
              </Box>
            }
            {c["assistant"] &&
              <Box className='bubble left'>
                <Box className='avatar'> <img src={PinkyAvatar} alt='assistant' /></Box>
                <Box className="wrap"><p className='content'>{c["assistant"]}</p></Box>
              </Box>
            }
          </Box>
        )}
      </Box>
      <Box
        height={116}
        className='inputContainer'>
        <Container id="inputHintBar">
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              width: '100%',
              '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar in Chrome, Safari, and Opera
              '-ms-overflow-style': 'none',  // Hide scrollbar in Internet Explorer and Edge
              'scrollbar-width': 'none'  // Hide scrollbar in Firefox
            }}
          >
            <Chip
              sx={{ mx: 1, flexShrink: 0 }}
              label={t('chatbot.presetQuestions.reserve')}
              variant="outlined"
              onClick={chipClickHandler}
            />
            <Chip
              sx={{ mx: 1, flexShrink: 0 }}
              label={t('chatbot.presetQuestions.photoEdit')}
              variant="outlined"
              onClick={chipClickHandler}
            />
          </Box>
        </Container>
        <Container id="inputbar">
          <TextField
            fullWidth
            id='userQuestion'
            placeholder="Send a message..."
            InputProps={{
              endAdornment: (
                <IconButton sx={{ height: '100%' }} onClick={submitHandler} disabled={sendButtonDisabled}>
                  <InputAdornment sx={{ height: '100%' }} position="end">
                    <SendIcon color='success' />
                  </InputAdornment>
                </IconButton>
              ),
            }} />
        </Container>
      </Box>
    </Box>
  );
}