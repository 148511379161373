import React from 'react';
import { Route, Routes } from "react-router-dom";
// import HomePage from './components/HomePage/HomePage';
// import MenuPage from './components/MenuPage/MenuPage';
// import LeasePage from './components/LeasePage/LeasePage';
// import LocationPage from './components/LocationPage/LocationPage';
// import CollectionPage from './components/CollectionPage/CollectionPage'
import AboutPage from './components/AboutPage/AboutPage';
import TestPaymentPage from './components/TestPaymentPage'
import "./App.css"
import { ThemeProvider } from '@mui/material/styles';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import StudioPage from './components/StudioPage';

// Auth
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import ClosePage from './components/ClosePage';
import MyStudioPage from './components/MyStudio/MyStudioPage';
import PlatformHomePage from './components/PlatformHomePage';
import RegistrationPage from './components/RegistrationPage';
import StudioRegisterPage from './components/MyStudio/StudioRegisterPage';
import Footer from './components/Footer';
import Navbar from './components/Navbar/Navbar';
import OrderDetail from './components/OrdersPage/OrderDetail';
import { Box } from '@mui/material';
import airyVibeTheme from './airyVibeTheme';
import Orders from './components/OrdersPage/Orders';
import { SnackbarProvider } from './components/providers/SnackbarProvider';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App() {
  function navFooter(component) {
    return <Box
      display={'grid'}
      grid-template-rows={' auto 1fr auto'}
      minHeight={'100vh'}>
      <Navbar />
      {component}
      <Footer />
    </Box>
  }

  return (
    <ThemeProvider theme={airyVibeTheme}>
      <SnackbarProvider>
        <Routes>
          <Route exact path="/" element={navFooter(<PlatformHomePage />)} />
          {/*
          <Route exact path="/chatbot" element={<ChatbotPage />} />
          <Route exact path="/menu" element={<MenuPage />} />
          <Route exact path="/lease" element={<LeasePage />} />
          <Route exact path="/location" element={<LocationPage />} />
          <Route exact path="/collection" element={<CollectionPage />} />
          <Route exact path="/oldHome" element={<HomePage />} />
          <Route exact path="/test" element={navFooter(<TestPage />)} />
          <Route path="/dts" element={<TimeTable />} />
          <Route path="/dtsp" element={<TimePage />} />
          */}
          <Route exact path="/about" element={navFooter(<AboutPage />)} />
          <Route exact path="/testPayment" element={<TestPaymentPage />} />
          <Route exact path="/paymentSuccess" element={navFooter(<PaymentSuccessPage />)} />
          <Route exact path="/login" element={navFooter(<LoginPage />)} />
          <Route exact path="/profile" element={navFooter(<ProfilePage />)} />
          <Route exact path="/mystudio" element={navFooter(<MyStudioPage />)} />
          <Route exact path="/closePage" element={navFooter(<ClosePage />)} />
          <Route path="/at/:studioName" element={navFooter(<StudioPage />)} />
          <Route path="/order/:orderId" element={navFooter(<OrderDetail />)} />
          <Route exact path="/order" element={navFooter(<Orders />)} />

          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/studioRegister" element={<StudioRegisterPage />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;