import React, { useEffect, useState } from 'react';

import { Button, Box, TextField } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import { getUserAndData } from '../User';
import { updateUserData } from '../apis';
import { useSnackbar } from '../providers/SnackbarProvider';

function MyStudioSummaryForm({ user, submitCallback }) {
    const callSnackbar = useSnackbar();
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    const [studioSummary, setStudioSummary] = useState('');
    const [photographerSummary, setPhotographerSummary] = useState('');

    const [errors, setErrors] = useState({
        studioSummary: false,
        photographerSummary: false
    });
    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);
    let requested = false;
    async function handleSubmit(e) {
        e.preventDefault();
        // Validate required fields
        if (!studioSummary || !photographerSummary) {
            setErrors({
                studioSummary: !studioSummary,
                photographerSummary: !photographerSummary,
            });
            return;
        }
        const formData = {
            studioSummary,
            photographerSummary
        };
        console.log(formData);
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['studioSummary'] = studioSummary;
        updatedUserData['photographerSummary'] = photographerSummary;
        const apiResponse = JSON.parse(await updateUserData(user, userData, updatedUserData));
        console.log(apiResponse);
        console.log(apiResponse['statusCode']);
        console.log(apiResponse['statusCode'] === 200);
        if (apiResponse['statusCode'] === 200) {
            callSnackbar('Successfully updated studio summary.', 3000, 'success');
            if (submitCallback) {
                submitCallback();
            }
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['error'])
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const userAndData = await getUserAndData();
            setUserData(userAndData.userData);
            if ('studioSummary' in userAndData.userData) {
                console.log('studioSummary exists');
                setStudioSummary(userAndData.userData.studioSummary);
            } else {
                console.error('studioSummary not found');
            }
            if ('photographerSummary' in userAndData.userData) {
                console.log('photographerSummary exists');
                setPhotographerSummary(userAndData.userData.photographerSummary);
            } else {
                console.error('photographerSummary not found');
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        if (!errorMessage) errorMessage = "";
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (<Box id={'MyStudioSummaryForm'}>
        <SimpleDialog
            id="simple-dialog"
            open={dialogOpen}
            title={dialogTitle}
            content={dialogContentArray}
            onClose={() => { setDialogOpen(false) }}
        ></SimpleDialog>
        {init ? (
            <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                    <TextField
                        label="工作室介绍"
                        value={studioSummary}
                        error={errors.studioSummary}
                        onChange={(e) => setStudioSummary(e.target.value)}
                        variant="outlined"
                        required multiline
                        rows={4} />
                    <TextField
                        label="摄影师介绍"
                        value={photographerSummary}
                        error={errors.photographerSummary}
                        onChange={(e) => setPhotographerSummary(e.target.value)}
                        variant="outlined"
                        required multiline
                        rows={4} />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>)
            :
            (<>Loading ...</>)
        }
    </Box>
    );
}
export default MyStudioSummaryForm;