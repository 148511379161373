import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Button, Box, Grid, Link, Typography } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getUserAndData } from './User';
import { useNavigate } from 'react-router-dom';
import ProfileStripe from './ProfileStripe';
import ProfileBasicInfoForm from './ProfileBasicInfoForm';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function ProfilePage() {
    const navigate = useNavigate();
    let requested = false;
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState(undefined);
    const [contentSelected, setContentSelected] = useState('basicInfo');
    useEffect(() => {
        if (requested) return;
        requested = true;
        async function loadUser() {
            const userAndData = await getUserAndData();
            if (userAndData.user === null) {
                console.warn("Load user failed.\nMaybe you are not login yet.\nRedirect to login page in 5 second.");
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
            if (userAndData.userData === null) {
                console.warn("Load user data failed.");
                return;
            }
            setUserType(userAndData.userData.userType);
            setUser(userAndData.user);
        }
        loadUser();
    }, [])
    useEffect(() => {
        if (!user) return;
        if (userType === undefined) {
            console.log("this user does not have userType set, redirect to registration.");
            const timer = setTimeout(() => {
                navigate('/registration');
            }, 1000);
            return () => clearTimeout(timer); // Cleanup
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function handleTitleClick(contentName) {
        if (contentSelected === contentName) {
            return;
        }
        setContentSelected(contentName);
    }
    function handleBreadcrumbClick(event) {

    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleBreadcrumbClick}>
            Airy Vibe
        </Link>,
        <Typography key="3" color="text.primary">
            Profile
        </Typography>,
    ];

    return (
        <Box className='ProfilePage'>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            {userType === 'photographer' && <Box id='contentBox-photographer'>
                <Grid container>
                    <Grid item xs={2}>
                        <Box sx={{ backgroundColor: "#eeeeee", padding: { xs: "3px", md: 2 } }}>
                            <Button
                                fullWidth
                                size='small'
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    display: "flex !important",
                                    justifyContent: "left !important",
                                    color: '#000000'
                                }}
                                onClick={() => { handleTitleClick('basicInfo') }}
                            >
                                基本信息
                            </Button>
                            <Button
                                fullWidth
                                size='small'
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    display: "flex !important",
                                    justifyContent: "left !important",
                                    color: '#000000'
                                }}
                                onClick={() => { handleTitleClick('Stripe') }}
                            >
                                收款账户
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        {contentSelected === 'basicInfo' && <ProfileBasicInfoForm user={user} />}
                        {contentSelected === 'Stripe' && <ProfileStripe user={user} />}
                    </Grid>
                </Grid>
            </Box>}

            {userType === 'customer' && <Box id='contentBox-customer'>
                <Grid container>
                    <Grid item xs={2}>
                        <Box sx={{ backgroundColor: "#eeeeee", padding: { xs: "3px", md: 2 } }}>
                            <Button
                                fullWidth
                                size='small'
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    display: "flex !important",
                                    justifyContent: "left !important",
                                    color: '#000000'
                                }}
                                onClick={() => { handleTitleClick('basicInfo') }}
                            >
                                基本信息
                            </Button>
                            <Button
                                fullWidth
                                size='small'
                                sx={{
                                    textAlign: 'left',
                                    fontSize: 18,
                                    display: "flex !important",
                                    justifyContent: "left !important",
                                    color: '#000000'
                                }}
                                onClick={() => { handleTitleClick('notificationSetting') }}
                            >
                                通知设置
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={10} textAlign='center'>
                        <p>暂未开发</p>
                    </Grid>
                </Grid>
            </Box>}
        </Box >
    );
}
export default withAuthenticator(ProfilePage);