import React, { useEffect, useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, Drawer, TextField, Rating } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserAndData } from '../User';
import { getOrder, updateOrder, submitReview } from '../apis';
import DraggableImageList from '../DraggableImageList';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import { StyledBreadcrumb } from '../BreadCrumb/BreadCrumb';
import dayjs from 'dayjs';
import CopyTypography from '../../widgets/CopyTypography/CopyTypography';
import { pinkStyle } from '../../airyVibeTheme';
import ImgListDialog from '../ImgDialog/ImgListDialog';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../providers/SnackbarProvider';


function getPaymentStatus(charge) {
    if (!charge.paid) return 'unpaid';
    if (!charge.refunded) return 'paid';
    if (charge.paid && charge.refunded) return 'refunded';
}

function getTimeString(unixTimestamp, timezone = "America/Los_Angeles") {
    const date = new dayjs.tz(unixTimestamp, timezone);
    return `${(date.format('M/D/YYYY, h:mm:ssAZ[Z]'))} (${timezone})`
}

function ReviewDrawer({ onSubmit, initialReviewData, order }) {
    const callSnackbar = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [qualityRating, setQualityRating] = useState(initialReviewData.qualityRating);
    const [guidingRating, setGuidingRating] = useState(initialReviewData.guidingRating);
    const [communicationRating, setCommunicationRating] = useState(initialReviewData.communicationRating);
    const [review, setReview] = useState(initialReviewData.review);
    const [selectedImages, setSelectedImages] = useState([]);
    const { t } = useTranslation();

    const isFormValid = qualityRating && guidingRating && communicationRating && review;

    const handleSubmit = async () => {
        try {
            await onSubmit({ qualityRating, guidingRating, communicationRating, review, selectedImages });
            setIsOpen(false);
            callSnackbar('Review submitted successfully', 3000, 'success');
        } catch (error) {
            console.error('Error submitting review:', error);
            const errorMessage = error.message || 'An error occurred while submitting the review';
            callSnackbar('Error submitting review: ' + errorMessage, 3000, 'error');
        }
    };

    const handleReviewChange = (e) => {
        const input = e.target.value;
        if (input.length <= 600) {
            setReview(input);
        }
    };

    const handleImageSelect = (imageId) => {
        setSelectedImages(prev => {
            if (prev.includes(imageId)) {
                return prev.filter(id => id !== imageId);
            } else if (prev.length < 20) {
                return [...prev, imageId];
            }
            return prev;
        });
    };

    // Add this new function to get the selection status message
    const getSelectionStatusMessage = () => {
        const count = selectedImages.length;
        if (count === 0) {
            return t('order.noImagesSelected');
        } else if (count === 20) {
            return t('order.maxImagesSelected');
        } else {
            return t('order.imagesSelectedCount', { count, max: 20 });
        }
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setIsOpen(true)}
                sx={{ mt: 2 }}
            >
                {t('order.writeReview')}
            </Button>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box sx={{ p: 2, width: '100%', maxWidth: 500, margin: '0 auto' }}>
                    <Typography variant="h6" gutterBottom>
                        {t('order.writeYourReview')}
                    </Typography>
                    <Typography fontSize={14}>{t('studio.ratingCategory.final_quality')}</Typography>
                    <Rating
                        value={qualityRating}
                        onChange={(event, newValue) => setQualityRating(newValue)}
                        sx={{ mb: 1, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <Typography fontSize={14}>{t('studio.ratingCategory.guiding')}</Typography>
                    <Rating
                        value={guidingRating}
                        onChange={(event, newValue) => setGuidingRating(newValue)}
                        sx={{ mb: 1, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <Typography fontSize={14}>{t('studio.ratingCategory.communication')}</Typography>
                    <Rating
                        value={communicationRating}
                        onChange={(event, newValue) => setCommunicationRating(newValue)}
                        sx={{ mb: 2, "& .MuiRating-icon": { color: "#FF9696" } }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                        label={t('order.writeYourReview')}
                        value={review}
                        onChange={handleReviewChange}
                        inputProps={{ maxLength: 600 }}
                        helperText={`${review.length}/600`}
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="h6" gutterBottom>
                        {t('order.selectImages')}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {order.images.map((image) => (
                            <Box
                                key={image.imageId}
                                sx={{
                                    position: 'relative',
                                    width: 100,
                                    height: 100,
                                    cursor: 'pointer',
                                    border: selectedImages.includes(image.imageId) ? '2px solid #FF9696' : '2px solid transparent',
                                }}
                                onClick={() => handleImageSelect(image.imageId)}
                            >
                                <img
                                    src={image.thumbnailSignedUrl || image.imageSignedUrl}
                                    alt={`pic-${image.imageId}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                {selectedImages.includes(image.imageId) && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            bgcolor: '#FF9696',
                                            borderRadius: '50%',
                                            width: 20,
                                            height: 20,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" sx={{ color: 'white', fontWeight: 'bold' }}>&#10003;</Typography>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Box>
                        <Typography variant="caption" fontSize={10}>{getSelectionStatusMessage()}</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!isFormValid}
                        sx={{ mt: 2 }}
                    >
                        {t('order.submitReview')}
                    </Button>
                    {!isFormValid && (
                        <Typography color="error" sx={{ mt: 1, fontSize: '0.875rem' }}>
                            {t('order.reviewFormIncomplete')}
                        </Typography>
                    )}
                </Box>
            </Drawer>
        </>
    );
}

function OrderDetail() {
    let params = useParams();
    const orderId = params.orderId;
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState();
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [isImageQualityOriginal, setImageQualityOriginal] = useState(true);
    const location = useLocation();
    let requested = false;
    const { t } = useTranslation();

    const getDelayFromURL = () => {
        const searchParams = new URLSearchParams(location.search);
        const delay = searchParams.get('delay');
        return delay ? parseInt(delay, 10) : 0;
    };

    useEffect(() => {
        if (requested) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        requested = true;
        async function loadOrder() {
            const { user, userData } = await getUserAndData();
            setUser(user);
            setUserData(userData);
            if (user === null) {
                console.warn("Load user failed.\nMaybe you are not login yet.\nRedirect to login page in 5 second.");
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
            const delay = getDelayFromURL();
            if (delay > 0) {
                await new Promise(resolve => setTimeout(resolve, delay * 1000));
            }
            const response = await getOrder(user, orderId, true);
            console.log(response);
            if (!('images' in response.body)) {
                response.body.images = []
            }
            setOrderData(response.body);
        }
        loadOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [reviewData, setReviewData] = useState({
        qualityRating: 0,
        guidingRating: 0,
        communicationRating: 0,
        review: ''
    });

    const handleReviewSubmit = async (reviewData) => {
        try {
            const response = await submitReview(user, orderId, reviewData);
            if (response.statusCode === 200) {
                // Update the local state with the submitted data
                setReviewData(reviewData);
                // Optionally, update the order data if the API returns updated order information
                const newOrderData = { ...orderData, review: response.order?.review };
                console.log('newOrderData', newOrderData);
                setOrderData(newOrderData);
                // TODO: Show a success message to the user
            } else {
                throw new Error(response.message || response.errorMessage || response.error || 'Failed to submit review');
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            throw error; // Re-throw the error to be caught in the ReviewDrawer
        }
    };

    return (
        <Box className='OrderDetail' >
            <Box
                sx={{ px: 1, padding: { xs: "3px", md: 2 } }}
                className='pageContent'
                maxWidth='1920px'
            >
                <Box mb={2}>
                    <CustomizedBreadcrumbs />
                </Box>
                {orderData?.status && (orderData.status !== 'unpaid' || orderData.status !== 'cancelled') && <OrderStepper />}
                <OrderCard
                    order={orderData}
                />
                {
                    (user && orderData)
                        ? <OrderAction user={user} userData={userData} order={orderData} setOrderData={setOrderData} />
                        : <p>{t('order.loading_data')}</p>
                }
            </Box>
        </Box >
    );


    function OrderStepper() {
        const steps = {
            'paid': 0,
            'accepted': 1,
            'photoPicking': 2,
            'completed': 3
        };
        return (
            <Stepper activeStep={orderData?.status ? steps[orderData.status] : 0} alternativeLabel>
                {Object.keys(steps).map((label) => (
                    <Step key={label}
                        sx={{
                            'svg.Mui-completed': { color: '#FF9696' },
                            'svg.Mui-active': { color: '#FF9696' }
                        }}
                    >
                        <StepLabel>{t(`order.status${label.charAt(0).toUpperCase() + label.slice(1)}`)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )

    }
    function OrderAction({ order, userData, user, setOrderData }) {
        const callSnackbar = useSnackbar();
        // image dialog related
        const [imageDialogOpen, setImageDialogOpen] = useState(false);
        const [imageDialogSrc, setimageDialogSrc] = useState([]);
        const [imageDialogIndex, setimageDialogIndex] = useState(0);
        let imgDialogHandleClose = () => { setImageDialogOpen(false) };
        const callImageDialog = (images, index) => {
            setimageDialogSrc(images)
            setimageDialogIndex(index)
            setImageDialogOpen(true)
        }

        useEffect(() => {
            console.log('order::', order);
        }, [])

        function handleImagesChange(newImagesItem) {
            const cloneOrder = JSON.parse(JSON.stringify(order));
            cloneOrder.images = newImagesItem;
            setOrderData(cloneOrder)
        }
        // iu2
        // image system document:
        // https://docs.google.com/document/d/1NYib6vjfzefGK5V94D3AmM5WnfGfHqRqgpM4ZmE0unM/edit
        async function uploadToOrder(newImages) {
            try {
                const updateOption = 'photographer:uploadImages';
                const updateContent = JSON.stringify(newImages);
                const apiResponse = JSON.parse(await updateOrder(user, orderId, updateOption, updateContent));
                console.log('apiResponse= ', apiResponse);
                if (apiResponse.statusCode === 200 && apiResponse['updateContent']) {
                    console.log("updateContent", apiResponse['updateContent']);
                    callSnackbar('Images uploaded successfully', 3000, 'success');
                    return apiResponse['updateContent'];
                } else {
                    throw new Error('Upload URL not received');
                }
            } catch (error) {
                console.error('Error uploading images:', error);
                callSnackbar('Error uploading images: ' + error.message, 3000, 'error');
            }
        }

        return (
            <Box>
                {
                    (order.status === 'paid' && userData.userType === 'photographer') &&
                    <Box>
                        <OrderActionButton
                            label={t('order.accept_order')}
                            user={user}
                            orderId={orderId}
                            updateOption={'photographer:accept'}
                            updateContent={'photographer:accept'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.accept_order_message')}
                    </Box>
                }
                {
                    (order.status === 'paid' && userData.userType === 'customer') &&
                    <Box>
                        <Button variant='contained' disabled={true}>
                            {t('order.accept_order')}
                        </Button>
                        {t('order.waiting_for_photographer_confirmation')}
                    </Box>
                }
                {
                    (order.status === 'accepted' && userData.userType === 'photographer') &&
                    <Box>
                        <OrderActionButton
                            label={order.confirmed_service_by_photographer ? t('order.service_confirmed') : t('order.confirm_service')}
                            user={user}
                            orderId={orderId}
                            disabled={order.confirmed_service_by_photographer}
                            updateOption={'photographer:confirm_service'}
                            updateContent={'photographer:confirm_service'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.waiting_for_photographer_upload')}
                    </Box>
                }
                {
                    (order.status === 'accepted' && userData.userType === 'customer') &&
                    <Box>
                        <OrderActionButton
                            label={order.confirmed_service_by_customer ? t('order.service_confirmed') : t('order.confirm_service')}
                            user={user}
                            orderId={orderId}
                            disabled={order.confirmed_service_by_customer}
                            updateOption={'customer:confirm_service'}
                            updateContent={'customer:confirm_service'}
                            orderDataCallback={setOrderData}
                        />
                        {t('order.waiting_for_photographer_upload')}
                    </Box>
                }
                <Box id='photoPicking-completed-image-list-container'>
                    {userData.userType === 'customer' &&
                        <ImgListDialog
                            imageDialogOpen={imageDialogOpen}
                            imgDialogHandleClose={imgDialogHandleClose}
                            imageDialogSrc={imageDialogSrc}
                            index={imageDialogIndex}
                        />
                    }
                    {userData.userType === 'photographer'
                        ?
                        <DraggableImageList
                            galleryList={order.images}
                            onListChangeHandler={handleImagesChange}
                            uploadToOrder={uploadToOrder}
                            isImageQualityOriginal={isImageQualityOriginal}
                        />
                        :
                        <>
                            {order.images.length > 0 ?
                                <Box
                                    key={'img-container'}
                                    display={'flex'}
                                    flexWrap={'wrap'}
                                    justifyContent={'flex-start'}
                                    maxWidth={'95vw'}>
                                    {order.images.map((image, index2) => (
                                        <Box
                                            key={'img-' + index2}
                                            width={150}
                                            height={150}
                                            border={'solid #80808066 1px'}
                                            m={1}
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            onClick={() => {
                                                callImageDialog(order.images.map((image) => {
                                                    return isImageQualityOriginal ? image.imageSignedUrl : image.thumbnailSignedUrl || image.imageSignedUrl
                                                }), index2)
                                            }}
                                        >
                                            <img
                                                src={isImageQualityOriginal ? image.imageSignedUrl : image.thumbnailSignedUrl || image.imageSignedUrl}
                                                alt={'review img'}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    width: 'auto',
                                                    height: 'auto',
                                                    alignSelf: 'center',
                                                }} />
                                        </Box>))
                                    }
                                </Box>
                                :
                                <Typography>
                                    {t('order.waiting_for_photographer_upload')}
                                </Typography>
                            }
                        </>
                    }
                </Box>
                <Box id='photoPicking-completed-action-container'>
                    {
                        (order.status === 'photoPicking' && userData.userType === 'customer') &&
                        <>
                            <OrderActionButton
                                label={t('order.complete_order')}
                                user={user}
                                orderId={orderId}
                                updateOption={'customer:complete'}
                                updateContent={'customer:complete'}
                                orderDataCallback={setOrderData}
                            />
                            {t('order.service_satisfied')}
                        </>
                    }
                </Box>
                <Box id='order-review-container'>
                    {
                        (order.status === 'completed' && userData.userType === 'customer' && !order.review) &&
                        <ReviewDrawer
                            onSubmit={handleReviewSubmit}
                            initialReviewData={reviewData}
                            order={order}
                        />
                    }
                    {
                        (order.status === 'completed' && order.review) &&
                        <Typography>{t('order.reviewCompleted')}</Typography>
                    }
                </Box>
            </Box>
        );
    }

    function OrderCard({ order }) {

        useEffect(() => {
            console.log('order::', order);
        }, [])
        if (order === undefined)
            return (<p>{t('loading')}</p>);
        return (
            <Box>
                <p>{t('order.studio')}: {order.studioName} </p>
                <CopyTypography copyContent={order.orderID} color='black' fontFamily={'Heebo'}>
                    {t('order.orderID')}: {order.orderID}
                </CopyTypography>
                <p>{t('order.orderStatus')}: {t(`order.status${order.status.charAt(0).toUpperCase() + order.status.slice(1)}`)}</p>
                {order.charge_item && <>
                    <p>{t('order.payment_status')}: {getPaymentStatus(order.charge_item)}</p>
                    <p>{t('order.total_amount')}: {order.charge_item.amount / 100} {order.charge_item.currency}</p>
                    {order.charge_item.refunded && <p>{t('order.refunded_amount')}: {order.charge_item.amount_refunded / 100} {order.charge_item.currency}</p>}
                    <p>{t('order.customer_email')}: {order.charge_item.receipt_email}</p>
                </>
                }
                <p>{t('order.invoice')}: {order.invoice && order.invoice.hosted_invoice_url ? <a href={order.invoice.hosted_invoice_url}>{t('order.stripe_receipt')}</a> : t('order.not_available')} </p>

                <p>{t('order.created_time')}: {getTimeString(order.checkoutSessionCreatedTime * 1000)}</p>
                {(order.time_reserved && order.time_reserved.status === 'reserved') && <>
                    <p>{t('order.reserved_time_start')}: {getTimeString(order.time_reserved.time_start, order.time_reserved.time_zone)} </p>
                    <p>{t('order.reserved_time_end')}: {getTimeString(order.time_reserved.time_end, order.time_reserved.time_zone)}</p>
                </>}
            </Box>
        );
    }

}
export default OrderDetail;



function CustomizedBreadcrumbs() {
    const { t } = useTranslation();
    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/"
                    label={t('home')}
                    sx={pinkStyle}
                />
                <StyledBreadcrumb component="a" href="/order" label={t('order.allOrders')} sx={pinkStyle} />
                <StyledBreadcrumb label={t('order.seeDetails')} sx={pinkStyle} />
            </Breadcrumbs>
        </div>
    );
}

function OrderActionButton({ label, user, orderId, updateOption, updateContent, orderDataCallback, disabled }) {
    const [actionButtonDisable, setActionButtonDisable] = useState(false);
    const callSnackbar = useSnackbar();

    return (
        <Button
            variant='contained'
            disabled={disabled || actionButtonDisable}
            onClick={async () => {
                setActionButtonDisable(true);
                try {
                    const updateResult = JSON.parse(await updateOrder(user, orderId, updateOption, updateContent));
                    if (updateResult.statusCode === 200) {
                        orderDataCallback(updateResult.order);
                        callSnackbar('Order updated successfully', 3000, 'success');
                    } else {
                        throw new Error(updateResult.message || 'Failed to update order');
                    }
                } catch (error) {
                    console.error('updateResult', error);
                    callSnackbar('Error updating order: ' + error.message, 3000, 'error');
                    setActionButtonDisable(false);
                }
            }}
        >
            {label}
        </Button>
    );
}