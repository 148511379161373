import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, SwipeableDrawer, Grid, Chip, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getReservableSlotsWithInterval, formatTime, isReservationTimeValid, checkReservationTimeError } from './commonUtils';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar, DateTimeField } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

const Puller = styled(Box)(({ theme }) => ({
    width: 60,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 30px)',
}));

export default function SelectTimeDrawer({ openHours, reservationLength, onTimeChange, studioBookedTime }) {
    const { t } = useTranslation();
    SelectTimeDrawer.propTypes = {
        openHours: PropTypes.arrayOf(
            PropTypes.shape({
                day: PropTypes.string.isRequired,
                start: PropTypes.string.isRequired,
                end: PropTypes.string.isRequired,
            })
        ).isRequired,
        reservationLength: PropTypes.number.isRequired,
    }
    const [drawerOpen, setDrawerOpen] = useState(false);
    // reservation date and time
    const [reservationDate, setReservationDate] = useState(dayjs());
    const [reservationTime, setReservationTime] = useState(null);
    const [reservationTimeError, setReservationTimeError] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        const [hour, minute] = option.split(':').map(Number);
        const newTime = reservationDate.set('hour', hour).set('minute', minute).set('second', 0);
        setReservationTimeError(checkReservationTimeError(newTime));
        setReservationTime(newTime);
    };
    const resetOption = () => { setSelectedOption(null); }

    const content = () => (
        <Box
            width={'auto'}
            role="presentation"
        >
            <Puller />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {/* Calendar Here */}
                        <DateCalendar
                            minDate={dayjs()}
                            value={reservationDate} onChange={
                                (newValue) => {
                                    setReservationDate(newValue);
                                    resetOption();
                                }} />
                    </Grid>
                    <Grid item xs={12} md={6} padding={2}>
                        <Box>
                            {/* Time Length Here */}
                            {t('studio.serviceLength') + `: ${formatTime(reservationLength)}`}
                        </Box>
                        <Box>
                            {/* Time Slots Here */}
                            {getReservableSlotsWithInterval(openHours, reservationDate, reservationLength, studioBookedTime).map((option, index) => (
                                <Chip
                                    key={'time-option-chip-' + index}
                                    onClick={() => handleOptionClick(option)}
                                    variant={selectedOption === option ? 'contained' : 'outlined'}
                                    color={selectedOption === option ? 'primary' : 'primary'}
                                    label={option}
                                    sx={{ margin: '4px' }}
                                />
                            ))}
                        </Box>
                        {reservationTime !== null &&
                            <Grid container>
                                <Grid item xs={12} md={6} mt={2}>
                                    <DateTimeField
                                        label="From"
                                        value={reservationTime}
                                        disabled
                                        fullWidth
                                        error={!!reservationTimeError}
                                        helperText={reservationTimeError}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mt={2}>
                                    <DateTimeField
                                        label="To"
                                        value={reservationTime.add(reservationLength, 'minute')}
                                        disabled
                                        fullWidth
                                        error={!!reservationTimeError}
                                    />
                                </Grid>
                            </Grid>
                        }

                        <Grid container>
                            <Grid item xs={6} mt={2}>
                                <Button
                                    variant='contained'
                                    color='pink'
                                    sx={{ display: 'block', marginX: 'auto', }}
                                    disabled={!isReservationTimeValid(reservationTime)}
                                    onClick={() => {
                                        onTimeChange(reservationTime);
                                        setDrawerOpen(false);
                                    }}
                                >{t('constant.confirm')}</Button>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                                <Button
                                    variant='outlined'
                                    sx={{ display: 'block', marginX: 'auto' }}
                                    onClick={() => { setDrawerOpen(false) }}
                                >{t('constant.cancel')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Box >
    );

    return (
        <Box justifyContent='center' width={'100%'} display={'flex'}>
            <Button
                variant={'contained'}
                color='pink'
                onClick={() => { setDrawerOpen(true) }}
                fullWidth
                sx={{ my: 2, marginX: '32px', borderRadius: '10px' }}
            > {t('studio.adjustReservation')} </Button >
            <SwipeableDrawer
                anchor={'bottom'}
                open={drawerOpen}
                onClose={() => { setDrawerOpen(false) }}
                onOpen={() => { setDrawerOpen(true) }}
            >
                {content()}
            </SwipeableDrawer>
        </Box >
    );
}