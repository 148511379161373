import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Grid, Select, InputLabel, MenuItem, FormControl, IconButton, Switch, FormControlLabel } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import SimpleDialog from '../SimpleDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import { getUserData } from '../User';
import SevenTwentyFourTimeTable from '../TimeTable/SevenTwentyFourTimeTable';
import { transform724ToTimeData, transformTimeDataTo724, transform748ToTimeData, transformTimeDataTo748 } from '../TimeTable/timeUtils';
import SevenFourtyEightTimeTable from '../TimeTable/SevenFourtyEightTimeTable';
import { updateUserData } from '../apis';
const daySelection = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

function TimeRow({ timeItem, rowIndex, changeHandler, removeRow }) {
    return (
        <Box flexDirection='row' display='flex' gap={1}>
            <IconButton
                onClick={() => { removeRow(rowIndex); }}
                size='small'
            >
                <CancelIcon color='red' />
            </IconButton>
            <FormControl >
                <InputLabel id="day-select-label">Day</InputLabel>
                <Select
                    sx={{ minWidth: '80px', width: '140px' }}
                    labelId="day-select-label"
                    label="Day"
                    id="day-select"
                    value={timeItem.day}
                    onChange={(e) => {
                        let copy = JSON.parse(JSON.stringify(timeItem));
                        copy.day = e.target.value;
                        changeHandler(rowIndex, copy);
                    }}
                >
                    {daySelection.map((day) => (
                        <MenuItem key={'row-' + rowIndex + '-day-menu-item-' + day} value={day}>{day}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <TextField
                    sx={{ minWidth: '80px', width: '90px' }}
                    label="Start Time"
                    placeholder='11:00'
                    value={timeItem.start}
                    onChange={(e) => {
                        let copy = JSON.parse(JSON.stringify(timeItem));
                        copy.start = e.target.value;
                        changeHandler(rowIndex, copy);
                    }}
                />
            </FormControl>
            <FormControl>
                <TextField
                    sx={{ minWidth: '80px', width: '90px' }}
                    label="End Time"
                    placeholder='13:00'
                    value={timeItem.end}
                    onChange={(e) => {
                        let copy = JSON.parse(JSON.stringify(timeItem));
                        copy.end = e.target.value;
                        changeHandler(rowIndex, copy);
                    }}
                />
            </FormControl>
        </Box>
    );
}

function MyStudioTimeSetting({ user }) {

    const [halfHourChecked, setHalfHourChecked] = useState(false);
    // Simple Dialog
    const [dialogTitle, setDialogTitle] = useState('')
    const [dialogContentArray, setDialogContentArray] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)

    let requested = false;

    const [timeData, setTimeData] = useState([]);
    function timeDataChangeHandler(timeItemIndex, newTimeItem) {
        let copy = JSON.parse(JSON.stringify(timeData));
        copy[timeItemIndex] = JSON.parse(JSON.stringify(newTimeItem));
        setTimeData(copy);
    }
    function handleAddTimeRow() {
        setTimeData([...timeData, { day: '', start: '', end: '' }]);
    }
    function removeRow(rowIndex) {
        if (rowIndex >= timeData.length) return;
        let copy = JSON.parse(JSON.stringify(timeData));
        copy.splice(rowIndex, 1);
        setTimeData(copy);
    }

    const [init, setInit] = useState(false);
    const [userData, setUserData] = useState(null);

    function hasEmptyString(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }
    async function handleSubmit(e) {
        e.preventDefault();
        for (let index = 0; index < timeData.length; index++) {
            const timeItem = timeData[index]
            if (hasEmptyString(timeItem)) {
                showErrorDialog(444, 'Please fill in all blanks');
                return;
            }
        }
        let updatedUserData = JSON.parse(JSON.stringify(userData));
        updatedUserData['timeData'] = JSON.parse(JSON.stringify(timeData));
        console.log(updatedUserData);
        const apiResponse = JSON.parse(await updateUserData(user, userData, updatedUserData));
        console.log(apiResponse);
        console.log(apiResponse['statusCode']);
        if (apiResponse['statusCode'] === 200) {
            window.location.reload();
        } else {
            console.error("error");
            console.error(JSON.stringify(apiResponse));
            showErrorDialog(apiResponse['statusCode'], apiResponse['body']['errorMessage'])
        }
    }
    useEffect(() => {
        if (user === null) return;
        if (requested) return;
        requested = true;
        async function loadUserData() {
            const responseObject = await getUserData(user);
            if (responseObject === '{') {
                showErrorDialog(411, "Token expired.\nPlease refresh to continue.");
                return;
            }
            setUserData(responseObject);
            if ('timeData' in responseObject) {
                console.log('timeData exists');
                setTimeData(responseObject.timeData);
            } else {
                console.log('not found')
            }
            setInit(true);
        }
        loadUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    function showErrorDialog(errorCode, errorMessage) {
        let errorMessageArray = errorMessage.split('\n');
        const errorCodeMessage = `(Error Code: ${errorCode})`
        errorMessageArray.push(errorCodeMessage)
        setDialogContentArray(errorMessageArray);
        setDialogTitle('Error')
        setDialogOpen(true)
    }
    return (
        <Box id={'MyStudioTimeSetting'}>
            <SimpleDialog
                id="simple-dialog"
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContentArray}
                onClose={() => { setDialogOpen(false) }}
            ></SimpleDialog>
            {init ? (
                <Box sx={{ paddingX: { xs: "8px", md: 2, } }}>
                    <Button
                        variant="contained"
                        color="pink"
                        onClick={handleAddTimeRow}
                        sx={{ width: 200, mb: 2 }}
                    >
                        Add Time Row
                    </Button>
                    <Grid container>
                        <Grid item xs={12} lg={5}>
                            <Box sx={{ paddingX: { xs: "8px", md: 2, } }} gap={2} display='flex' flexDirection='column'>
                                {timeData.map((timeItem, index) => (
                                    <TimeRow
                                        key={'time-row-' + index}
                                        timeItem={timeItem}
                                        rowIndex={index}
                                        changeHandler={timeDataChangeHandler}
                                        removeRow={removeRow}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormControlLabel
                                value="Enable Half Hour"
                                control={<Switch
                                    checked={halfHourChecked}
                                    onChange={() => { setHalfHourChecked(!halfHourChecked) }}
                                    label="Every Half Hour"
                                />}
                                label="Every Half Hour"
                                labelPlacement="end"
                            />
                            {halfHourChecked ?
                                <SevenFourtyEightTimeTable
                                    timeSelected={transformTimeDataTo748(timeData)}
                                    setTimeSeleted={(input) => { setTimeData(transform748ToTimeData(input)) }} />
                                :
                                <SevenTwentyFourTimeTable
                                    timeSelected={transformTimeDataTo724(timeData)}
                                    setTimeSeleted={(input) => { setTimeData(transform724ToTimeData(input)) }}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>)
                :
                (<>Loading ...</>)
            }
        </Box>
    );
}
export default MyStudioTimeSetting;